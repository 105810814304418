import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Form, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { getAllReview } from '../HrSlice';
import { getFilterOptionData } from '../../Employee/EmpSlice';
import { setFilterTasksData } from '../../Common/AllTaskSlice';
import axios from 'axios';

export const HrReviewModel = ({ show, close,handleFilterTrue, handlerFilterFalse, showNoDataFound, currentPage, docsPerPage }) => {

  const dispatch = useDispatch()

  const loginUserData = useSelector((state) => state.loginData.userData)
  const allDropdownOption = useSelector(state => state?.employee?.getFilterDropdown)

  // state 
  const [projectName, setProjectName] = useState("")
  const [departmentName, setDepartmentName] = useState("")
  const [resourceName, setResourceName] = useState("")
  const [taskName, setTaskName] = useState("")
  const [idcMin, setIDCMin] = useState("")
  const [idcMax, setIDCMax] = useState("")
  const [qwdMin, setQWDMin] = useState("")
  const [qwdMax, setQWDMax] = useState("")
  const [slippage, setSlippage] = useState("")
  const [efficiency, setEfficiency] = useState("")
  const [reviewDate, setReviewDate] = useState("")

  
// for handler submit
  const handleFilterSubmit = () => {
    if (
      projectName === "" &&
      departmentName === "" &&
      resourceName === "" &&
      taskName === "" &&
      idcMin === "" &&
      idcMax === "" &&
      qwdMin === "" &&
      qwdMax === "" &&
      slippage === "" &&
      efficiency === "" &&
      reviewDate === ""
    ) {
      dispatch(setFilterTasksData([]));
      handlerFilterFalse();
    } else {
      getAllReviewData();
    }
    close();
  };

  // for clear button
  const clearFilter = () => {
    setProjectName("")
    setDepartmentName("")
    setResourceName("")
    setTaskName("")
    setIDCMin("")
    setIDCMax("")
    setQWDMin("")
    setQWDMax("")
    setSlippage("")
    setEfficiency("")
    setReviewDate("")
  }


  

  const getAllReviewData = async() => {
    const payload = {
      "empId": loginUserData?.empId,
      "assignBy_Name": "",
      "departments": [],
      "taskDepartmentName": departmentName.value ? departmentName.value : "",
      "taskName": taskName.value ?? "",
      "projectName": projectName.value ?? "",
      "resource_id": resourceName.value ?? "",
      "idcMin": idcMin === "" ? "0" : idcMin.value,
      "idcMax": idcMax === "" ? "10" : idcMax.value,
      "qwdMin": qwdMin === "" ? "0" : qwdMin.value,
      "qwdMax": qwdMax === "" ? "10" : qwdMax.value,
      "slippage": slippage.value ?? "",
      "efficiency": efficiency.value ?? "",
      "reviewDate": reviewDate ?? "",
      "resourceType": loginUserData?.resourceType,
      "documentsPerPage": docsPerPage,
      "page": currentPage
    }
    await axios.post(process.env.REACT_APP_NEW_BASE_URL + '/reviewTask', payload)
      .then((response) => {
        dispatch(setFilterTasksData(response?.data))
        handleFilterTrue()

      })
      .catch((error) => {
        console.log("Error", error)
      })
  }


   useEffect(() => {
    if (showNoDataFound) {
      getAllReviewData()
    }
  }, [currentPage, docsPerPage])

  
  useEffect(() => {
    let payload = {}

    if (loginUserData?.resourceType === "manager") {
      payload = {
        "departments": (loginUserData?.department).map(item => item.label),
        "empId": loginUserData?.empId,
        "resourceType": loginUserData?.resourceType
      }
    } else if (loginUserData?.resourceType === "teamlead") {
      payload = {
        "assignBy_Name": `${loginUserData?.firstName} ${loginUserData?.lastName}`,
        "empId": loginUserData?.empId,
        "resourceType": loginUserData?.resourceType
      }
    } else if (loginUserData?.resourceType === "employee") {
      payload = {
        "empId": loginUserData?.empId,
        "resourceType": "employee"
      }
    } else {
      payload = {
        "empId": loginUserData?.empId,
        "resourceType": "humanResource" // admin or humanResource
      }
    }

    dispatch(getFilterOptionData(payload))
  }, [])

  const IdcAndQwdOption = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ]

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center' }}><h5>Filter Reviews</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <Form.Group>
              <Form.Label> Project </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.data?.projects
                }
                value={projectName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select project name"
                onChange={(option) => { setProjectName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-6">
            <Form.Group>
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Department Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.data?.taskDepartments
                }
                value={departmentName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Department Name"
                onChange={(option) => { setDepartmentName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-12 mt-3">
            <Form.Group>
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Resource Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.data?.resources
                }
                value={resourceName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Resource Name"
                onChange={(option) => { setResourceName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-3 mt-3">
            <Form.Group>
              <Form.Label> Min IDC </Form.Label>
              <Select
                isMulti={false}
                options={IdcAndQwdOption}
                value={idcMin}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Min IDC"
                onChange={(option) => { setIDCMin(option) }}
                isSearchable={true} />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-3 mt-3">
            <Form.Group>
              <Form.Label> Max IDC </Form.Label>
              <Select
                isMulti={false}
                options={IdcAndQwdOption}
                value={idcMax}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Max IDC"
                onChange={(option) => { setIDCMax(option) }}
                isSearchable={true} />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-3 mt-3">
            <Form.Group>
              <Form.Label> Min QWD </Form.Label>
              <Select
                isMulti={false}
                options={IdcAndQwdOption}
                value={qwdMin}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Min QWD"
                onChange={(option) => { setQWDMin(option) }}
                isSearchable={true} />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-3 mt-3">
            <Form.Group>
              <Form.Label> Max QWD </Form.Label>
              <Select
                isMulti={false}
                options={IdcAndQwdOption}
                value={qwdMax}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Max QWD"
                onChange={(option) => { setQWDMax(option) }}
                isSearchable={true} />
            </Form.Group>
          </div>

          <div className="col-sm-12 col-md-6 mt-3">
            <Form.Group>
              <Form.Label className="emp-form-label fw-bold">
                Review Date
              </Form.Label>
              <InputGroup>
                <Form.Control type="date" onKeyDown={(event) => event.preventDefault()}
                  name="reviewDate" value={reviewDate}
                  onChange={(e) => setReviewDate(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </div>

        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
        <Button className="primaryBtn" onClick={() => { handleFilterSubmit() }}>Submit </Button>
      </Modal.Footer>
    </Modal>
  )
}


import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import DashCards from '../Common/DashCards';
import Select from "react-select";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import MultibarChart from '../Charts/MultibarCharts';
import HorizontalChart from '../Charts/HorizontalChart';
import * as Icon from 'react-bootstrap-icons';

// for excel sheet
import { writeFileSync } from 'xlsx';
import * as XLSX from "xlsx";

export const HrAllDashboard = () => {

  const userState = useSelector(state => state.loginData.userData);
  const EmpId = userState?.empId
  const departmentData = userState.department.map((itm) => {
    return { label: itm.label, value: itm.value };
  });
  const departmentDatas = userState.department.map((itm) => { return itm.label });

  const toggle = [{ name: 'ActualHours', value: 'ActualHours' },{ name: 'EstimateHours', value: 'EstimateHours' },];

  // ---- FILTER States -----
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // All dashboard graph
  const [dept, setDept] = useState([]);
  const [lead, setLead] = useState("")
  const [empList, SetEmpList] = useState("")
  const [resource, setResource] = useState([]);
  const [depart, setDepart] = useState([]);

   const [isResourceSelected, setIsResourceSelected] = useState(false);

  // state for toggle
   const [selectedRadio, setSelectedRadio] = useState('ActualHours');

  const [resourceLable, setResourceLable] = useState()
  const [resourceWiseActual, setResourceWiseActual] = useState()

  const [departmentLable, setDepartmentLable] = useState()
  const [resourceCount, setResourceCount] = useState()

  const [WeekendResource, setWeekendResource] = useState()
  const [WeekendActual, setWeekendActual] = useState()
  const [bifurcationData, setBifurcationData] = useState()
  const [bifurcationLabel, setBifurcationLabel] = useState()

  // for card api
  const [cardApiRes, setCardApiRes] = useState();

const downloadExcel = () => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);

  const sheetName = selectedRadio === 'ActualHours' ? 'Actual Hours' : 'Estimate Hours';
  
  // Initialize the data array with headers
  const data = [['ID','Name', `${sheetName} Hours`, 'Current Date']];

  // Get the current date in the format you desire (e.g., 'DD-MM-YYYY')
  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  
  // Define the field to use based on the selectedRadio value
  const hoursField = selectedRadio === 'ActualHours' ? 'actualHours' : 'estimateHours';

  // Iterate over your resourceLable data and add each row
  resourceLable.forEach(item => {
    const row = [
      item._id,
      item.name,
      item[hoursField], // Use the selected field (actualHours or estimateHours)
      currentDate,
    ];
    data.push(row);
  });

  // Add the data to the worksheet
  XLSX.utils.sheet_add_aoa(ws, data);


  XLSX.utils.book_append_sheet(wb, ws, `Resource Wise ${sheetName}`);

  const excelFileName = `ResourceWise ${sheetName} ${currentDate}.xlsx`;
  XLSX.writeFile(wb, excelFileName);
};





  // for toggle change graph
  const handleToggleChange = (value) => {
    // getResouceWiseTimeSpentData(value)
    setSelectedRadio(value); // Update the selected radio value
  };
const [payload,setPayload] = useState("")
  useEffect(() => {
  // Fetch data and set payload inside the callback
  const getResouceWiseTimeSpentData = async () => {
    try {
      const payload = {
        empId: EmpId,
        timeType: selectedRadio,
        department:dept?.label !== 'All' ? dept.label : "",
        resource_id: resource.value !== 'All' ? resource.value : "",
        lead: lead?.label !== 'All' ? lead.label : "",
        fromDate: startDate ? startDate : "",
        toDate: endDate ? endDate : "",
      };

      setPayload(payload);

      const res = await axios.post(process.env.REACT_APP_BASE_URL + "/graph/resourceWiseTimeSpentHR", payload);

      setResourceLable(res?.data?.data);

      const projectsArrayData = res.data.data.map((item) => ({
        type: "bar",
        label: "Resource",
        fill: true,
        data: selectedRadio === "ActualHours" ? item.actualHours : item.estimateHours,
        backgroundColor: "#4E79A7",
      }));
      setResourceWiseActual(projectsArrayData);
    } catch (error) {
      console.log("Error", error);
    }
  };

  getResouceWiseTimeSpentData();
}, [selectedRadio, dept, resource, lead, startDate, endDate, EmpId]);

// const [payload,setPayload] = useState("")
//  useEffect(() => {
//     const payload = {
//       empId: EmpId,
//       timeType: selectedRadio,
//       department: dept?.label ? dept.label : "",
//       resource_id: resource.value ? resource.value : "",
//       lead: lead?.label ? lead.label : "",
//       fromDate: startDate ? startDate : "",
//       toDate: endDate ? endDate : "",
//     };

//     console.log("payload",payload)
//     setPayload(payload);

//     getResouceWiseTimeSpentData(selectedRadio);
//   }, [selectedRadio, dept, resource, lead, startDate, endDate]);
// console.log("selectedradio",selectedRadio)

//   const getResouceWiseTimeSpentData = useCallback(async () => {
//     try {
//       await axios
//         .post(process.env.REACT_APP_BASE_URL + "/graph/resourceWiseTimeSpentHR", payload)
//         .then((res) => {
//           console.log("response",res)
//           setResourceLable(res?.data?.data)
          
//           let projectsArrayData = [];

//           res.data.data.map( item => {
//             projectsArrayData.push({
//               type: "bar",
//               label: "Resource",
//               fill: true,
//               data: selectedRadio === "ActualHours" ? item.actualHours : item.estimateHours,
//               backgroundColor: "#4E79A7",
//             });
//           } )
//           setResourceWiseActual(projectsArrayData)
//         })
//         .catch((err) => console.log("Error", err));
//     } catch (error) {
//       console.log("Error", "Something went wrong...!");
//     }
//   }, [dept,startDate,endDate,resource,lead,EmpId,selectedRadio]);

  const getDepartmentWiseResourceCount = useCallback(async () => {
    try {
      const payload = {
            "department":dept?.label !== 'All' ? dept.label : "",
            "resource_id": resource.value !== 'All' ? resource.value : "",
            "lead": lead?.label !== 'All' ? lead.label : "",
        // empId: EmpId,
        // fromDate: startDate ? startDate : "",
        // toDate: endDate ? endDate : "",
      };
      await axios
        .post(process.env.REACT_APP_BASE_URL + "/graph/departmentWiseResourceCountHR", payload)
        .then((res) => {
          setDepartmentLable(res?.data?.departments)

          let projectsArrayData = [];

          projectsArrayData.push({
            type: "bar",
            label: "Department",
            fill: true,
            data: res?.data?.count,
            backgroundColor: "#4E79A7",
          });
          setResourceCount(projectsArrayData)
        })
        .catch((err) => console.log("Error", err));
    } catch (error) {
      console.log("Error", "Something went wrong...!");
    }
  }, []);

  const getWeekendWorkingHrsTillNowData = useCallback(async () => {
    try {
      const payload = {
        empId: EmpId,
        fromDate: startDate ? startDate : "",
        toDate: endDate ? endDate : "",
      };
      await axios
        .post(process.env.REACT_APP_BASE_URL + "/graph/weekendWorkingHrsTillNowHR", payload)
        .then((res) => {
          setWeekendResource(res?.data?.data)

          let projectsArrayData = [];

          projectsArrayData.push({
            type: "bar",
            label: "Resource",
            fill: true,
            data: res?.data?.data,
            backgroundColor: "#4E79A7",
          });
          setWeekendActual(projectsArrayData)
        })
        .catch((err) => console.log("Error", err));
    } catch (error) {
      console.log("Error", "Something went wrong...!");
    }
  }, []);

  const getRoleWiseCountData = useCallback(async () => {
    try {
      const payload = {
        empId: EmpId,
        fromDate: startDate ? startDate : "",
        toDate: endDate ? endDate : "",
      };
      await axios
        .get(process.env.REACT_APP_BASE_URL + "/graph/bifurcationofResourceTypes", payload)
        .then((res) => {
          setBifurcationLabel(res?.data?.resourceTypes)

          let projectsArrayData = [];

          projectsArrayData.push({
            type: "bar",
            label: "Resource Type",
            fill: true,
            data: res?.data?.resourceTypeCounts,
            backgroundColor: "#4E79A7",
          });
          setBifurcationData(projectsArrayData)
        })
        .catch((err) => console.log("Error", err));
    } catch (error) {
      console.log("Error", "Something went wrong...!");
    }
  }, []);

  // for cards api
  const totalCardsApis = async () => {
    const payload = {
      empId: lead ? lead.value : EmpId,
      fromDate: startDate ? startDate : "",
      toDate: endDate ? endDate : "",
    };
    await axios.post(process.env.REACT_APP_BASE_URL + "/HRDashboard/HRDashboardAPI", payload)
      .then((res) => {
        setCardApiRes(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    totalCardsApis()
  }, [startDate, endDate, lead, resource, EmpId])

  // get departmentwise TL
  const [teamLeadList, setTeamLeadList] = useState([]);
  const getTLbyDept = async () => {
    const payload = {
      departmentName: dept ? dept.label : departmentDatas
    };
    await axios.post(process.env.REACT_APP_BASE_URL + "/department/getDepartmentTL", payload)
      .then((res) => {
        setTeamLeadList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllDepartment = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + "/department/getAllDepartment");
      setDepart(response.data.data); // Update depart state with the array from the response
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const departmentName = [
  { label: "All", value: "All" },
  ...(depart && Array.isArray(depart)
    ? depart.map((itm) => ({
        label: itm.departmentName,
        value: itm.departmentId,
      }))
    : []),
];


  const leadName = teamLeadList && Array.isArray(teamLeadList)
    ? teamLeadList.map((itm) => {
      return {
        label: itm.teamLeadName,
        value: itm.teamLeadId,
      };
    })
    : [];

  const getEmpByManagerId = async (e) => {
    const payload = {
      empIdTL: lead ? lead.value : EmpId,
    };
    await axios
      .post(process.env.REACT_APP_BASE_URL + "/emp/getParticularTlEmployee", payload)
      .then((res) => {
        SetEmpList(res.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for resource list
  const resourceList = empList
    ? empList
      .filter((itm) => itm.empId !== (lead ? lead.value : ''))
      .map((itm) => ({
        label: `${itm.firstName} ${itm.lastName}`,
        value: itm.empId,
      }))
    : [];

  const resourceData = [
    { label: "All", value: "All" },
    ...resourceList,
  ];

  useEffect(() => {
    getAllDepartment()
    // getResouceWiseTimeSpentData(selectedRadio)
  }, [])

  useEffect(() => {
    setResource("")
    getEmpByManagerId()
  }, [lead])

  useEffect(() => {
    setLead("")
    setResource("")
    getTLbyDept()
  }, [dept]);

  useEffect(() => {
    if (userState) {
      getWeekendWorkingHrsTillNowData();
      getDepartmentWiseResourceCount()
      getRoleWiseCountData()
    }
  }, [startDate, endDate, lead, resource, EmpId ,dept])

  return (
    <div className='EmpDash' data-aos='fade-up'>
      <Container>
        <Row className='justify-content-between w-100 mt-2'>
          <Col className='mb-4 mb-md-0'>
            <Form.Group>
              <Form.Label> Department </Form.Label>
              <Select
              //  className="custom-select"
                isMulti={false}
                isSearchable={true}
                value={dept}
                options={departmentName}
                // onChange={(selectedOption)=>{setDept(selectedOption);setLead("");setResource("")}}
                onChange={(selectedOption) => {
                  if (selectedOption && selectedOption.value === "All") {
                    setDept(selectedOption);
                    setLead("");
                    setResource([]);
                    setPayload((prevPayload) => ({ ...prevPayload, department: "" , lead:"" , resource_id:"" }));
                    totalCardsApis();
                  }
                  else {
                    setDept(selectedOption);
                    setPayload((prevPayload) => ({ ...prevPayload, department: selectedOption.label }));
                    setLead("");
                    setResource("");
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col className='mb-4 mb-md-0'>
            <Form.Group>
              <Form.Label> Lead/Manager </Form.Label>
              <Select
              // className="custom-select"
                isMulti={false}
                isSearchable={true}
                value={lead}
                options={leadName}
                onChange={(selectedOption)=>{setPayload((prevPayload) => ({ ...prevPayload, lead: selectedOption.label }));setLead(selectedOption);setResource("")}}
                // onChange={(selectedOption) => { setLead(selectedOption); setResource("") }}
              />
            </Form.Group>
          </Col>
          <Col className='mb-4 mb-md-0'>
            <Form.Group>
              <Form.Label>Resource</Form.Label>
              <Select
              // className="custom-select"
                isMulti={false}
                isSearchable={true}
                value={resource}
                // options={lead ? resourceList : []}
                options={userState?.resourceType === "teamlead" ? resourceData : lead ? resourceData : []}
                // onChange={(selectedOption) => setResource(selectedOption)}
                onChange={(selectedOption) => {
                  if (selectedOption && selectedOption.value === "All") {
                    setResource(selectedOption);
                    setIsResourceSelected(!!selectedOption); 
                    setPayload((prevPayload) => ({ ...prevPayload, resource_id: selectedOption.value !== "All"? selectedOption.value : " " }));
                    totalCardsApis();
                  }
                  else {
                    setResource(selectedOption);
                    setIsResourceSelected(!!selectedOption); 
                    setPayload((prevPayload) => ({ ...prevPayload, resource_id: selectedOption.value !== "All"? selectedOption.value : " "  }));
                    totalCardsApis();
                  }
                }}
              />
            </Form.Group>
          </Col>
          <Col md={4} className='d-flex justify-content-end mt-0 mt-mb-0'>
            <Row style={{ width: '100%' }}>
              <Col md={6} className='ps-0 mb-2 mb-md-0 '>
                <Form.Group>
                  <Form.Label className='w-100 THIS'> From </Form.Label>
                  <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </Form.Group>
              </Col>

              <Col md={6} className='ps-0'>
                <Form.Group>
                  <Form.Label> To </Form.Label>
                  <Form.Control type="date" value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>

        <DashCards totalResource={cardApiRes ? cardApiRes?.totalResources : 0}
          totalProj={cardApiRes ? cardApiRes?.totalProjects : 0} />

        <Row className='mt-4'>
          <Col md={12} className='px-3 mb-4 mb-md-0' >
              <Card style={{ padding: "10px 10px 35px 10px", borderRadius: '10px', border: 'none',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', textAlign: 'center'
              }}>
                <>
                  <ButtonGroup>
                    {toggle.map((radio, idx) => (
                      <ToggleButton
                      style = {{zIndex:'0'}}
                      // className='custom-select'
                        key={idx}
                        id={`radio-${idx+1}`}
                        type='radio'
                        variant={idx % 2 ? 'outline-success' : 'outline-danger'}
                        name='radio'
                        value={radio.value}
                        checked={selectedRadio === radio.value}
                        // onChange={() => handleToggleChange(radio.value)}
                        onClick={() => {
                          handleToggleChange(radio.value);
                          // handleDocsPerPage(10);
                        }}
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                 </ButtonGroup>
                </>

                {resourceWiseActual !== undefined && resourceWiseActual !== null &&  resourceWiseActual.length > 0 ? 
                  selectedRadio === 'ActualHours' ? (

                      <>
                      <Icon.Download onClick={downloadExcel} className='ms-auto mt-1 fs-3'/>
                      <HorizontalChart chartData={resourceLable} chartHeading="Resource Wise Actual and Estimate Hours Work" 
                      chartHeight={isResourceSelected ? 400 :2000} label="name" value="actualHours" color = {"#4E79A7"} />
                    
                      </>
                  ) : (
                    <>
                     <Icon.Download onClick={downloadExcel} className='ms-auto mt-1 fs-3'/>
                     <HorizontalChart chartData={resourceLable} chartHeading="Resource Wise Actual and Estimate Hours Work" 
                     chartHeight={isResourceSelected ? 400 :2000} label="name" value="estimateHours" color = {"#4E79A7"} />
                    </>
                  )
                  : ("")}
              </Card>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col md={12} className='px-3 mb-4 mb-md-0' >
            <Card style={{ padding: "10px 10px 35px 10px", borderRadius: '10px', border: 'none',
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', textAlign: 'center'
              }}>
                 {WeekendActual !== undefined && WeekendActual !== null && WeekendActual.length > 0 ? (
                <HorizontalChart chartData={WeekendResource} chartHeading="Weekend Resource Wise Work" 
                     chartHeight={isResourceSelected ? 400 :2000} label="_id" value="totalActualHrs" color = {"#4E79A7"} />
                )
              : ("")}
              </Card>
          </Col>
        </Row>

        {/* <Row className='mt-4'>
          <Col md={12} className='px-3 mb-4 mb-md-0' >
            {resourceCount !== undefined && resourceCount !== null && resourceCount.length > 0 ? (
              <MultibarChart chartLabels={departmentLable} chartData={resourceCount}
                chartHeading='Department Wise Resource Count' indexAxis="x" />
                )
              : ("")}
          </Col>
        </Row> */}
      </Container>
    </div >
  )
}


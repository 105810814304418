import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetDepartments = () => {
    const [allDepartmentOptions, setAllDepartmentOptions] = useState([])
    const [allDepartmentOptionsById, setAllDepartmentOptionsById] = useState([])


    useEffect(() => {
        try {
            axios.get(process.env.REACT_APP_BASE_URL + '/department/getAllDepartment')
                .then((res) => {
                    const filterOption = (res.data.data).length > 0 && (res.data.data).map((itm) => {
                        return ({ value: itm.departmentName, label: itm.departmentName })
                    })

                    const filterOptionById = (res.data.data).length > 0 && (res.data.data).map((itm) => {
                        return ({ value: itm.departmentId, label: itm.departmentName })
                    })

                    if (filterOption.length > 0) {
                        setAllDepartmentOptions(filterOption)
                        setAllDepartmentOptionsById(filterOptionById)
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }, [])



    return { allDepartmentOptions, allDepartmentOptionsById }
}

export default useGetDepartments
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row , InputGroup} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import useGetAllCategories from '../../Common/ReuseApi/useGetAllCategories';
import useGetDepartments from '../../Common/ReuseApi/useGetDepartments';

const AddProjectModel = ({show, close}) => {

      // toast notification
    const AddProjectSuccess = () => {
        toast.success("Added Successfully", {
        position: toast.POSITION.TOP_CENTER,
        });
    };

    const AlreadyProjectAdd = () => {
        toast.error("Project Already Added", {
        position: toast.POSITION.TOP_CENTER,
        });
    };

    const [projectName, setProjectName] = useState("")
    const [clientName, setClientName] = useState("")
    const [ProjectCategory, setProjectcategory] = useState("")
    const [departmentName, setDepartmentName] = useState("")
    const [projectType, setProjectType] = useState("")
    const [projectStatus, setProjectStatus] = useState("")

    const [projectNameError, setProjNameError] = useState(false);
    const [projectClientError, setProjClientError] = useState(false);
    const [projTypeError, setProjTypeError] = useState(false)
    const [projCategoryError, setProjCategoryError] = useState(false);
    const [projStatusError, setProjStatusError] = useState(false);
    const [projDeptError ,setProjDeptError] = useState(false)

    const { allCategoriesOptions } = useGetAllCategories()
    const { allDepartmentOptionsById } = useGetDepartments()
    const projectTypeOptions = [{ value: 'internal', label: 'Internal' }, { value: 'external', label: 'External' }];
    const projectStatusOptions = [{ value: 'inprogress', label: 'Inprogress' }, { value: 'completed', label: 'Completed' }, { value: 'planed', label: 'Planed' }, { value: 'in support', label: 'In Support' }];
    
    const handlerValidate = () => {
        let FormValid = true;
    
        if (projectName.length === 0) {
          FormValid = false;
          setProjNameError(true);
        }
        else if (clientName.length === 0) {
          FormValid = false;
          setProjClientError(true);
        }
        else if (projectType.length === 0) {
          FormValid = false;
          setProjTypeError(true);
        }
        else if (ProjectCategory.length === 0) {
          FormValid = false;
          setProjCategoryError(true);
        }
        else if (projectStatus.length === 0) {
          FormValid = false;
          setProjStatusError(true);
        }
    
        else if (departmentName.length === 0) {
          FormValid = false;
          setProjDeptError(true);
        }
        return FormValid;
      };

    const handlerSubmit = async (e) => {
        // e.preventDefault();
        setProjNameError(false);
        setProjClientError(false);
        setProjTypeError(false);
        setProjCategoryError(false);
        setProjStatusError(false);
        setProjDeptError(false)
    
        if (handlerValidate()) {
          const data = {
            projName: projectName,
            clientName: clientName,
            projectType: projectType.value,
            categoryName: ProjectCategory.label,
            status: projectStatus.value,
            departmentName: departmentName.label,
            departmentId: "",
            category_Id: ""
          };
          await axios
            .post(process.env.REACT_APP_BASE_URL + "/project/addproject", data)
            .then((res) => {
              console.log("project add", res);
              if(res.data.message === "Project already added."){
                AlreadyProjectAdd()
              }else{
                AddProjectSuccess()
                close()
                setProjectName("")
                setClientName("")
                setProjectcategory("")
                setDepartmentName("")
                setProjectType("")
                setProjectStatus("")
    }
            })
            .catch((error) => {
              console.log("Error", error)
            });
        }
      };

  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header
      closeButton
      style={{
        color: "black",
        width: "100%",
        height: "50px",
        backgroundColor: "#F9D65F",
      }}
    >
      <Modal.Title style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
        Create Project
      </Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label className="mt-3 fw-bold">
                Project Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Project Name"
                  className="input-field project-inputgroup"
                  name="projName"
                  value={projectName}
                  onChange={(e) => {
                    if (!e.target.value.match(/[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/) &&
                        e.target.value.trim()
                    ) {
                        setProjectName(e.target.value);
                    } else if (e.target.value.length === 0) {
                        setProjectName(e.target.value);
                    }
                    projectName.length >= 0
                      ? setProjNameError(false)
                      : setProjNameError(true);
                  }}
                />
              </InputGroup>
              {projectNameError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group controlId="">
              <Form.Label className="mt-3 fw-bold">
                Client Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Client Name"
                  name="clientName"
                  value={clientName}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      ) &&
                      e.target.value.trim()
                    ) {
                        setClientName(e.target.value);
                    } else if (e.target.value.length === 0) {
                        setClientName(e.target.value);
                    }
                    clientName.length >= 0
                      ? setProjClientError(false)
                      : setProjClientError(true);
                  }}
                />
              </InputGroup>
              {projectClientError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Category
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='categoryName'
                value={ProjectCategory}
                options={allCategoriesOptions}
                onChange={(option) => {
                  setProjectcategory(option);
                  ProjectCategory.length < 0
                    ? setProjCategoryError(true)
                    : setProjCategoryError(false);
                }}
              />
              {projCategoryError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Department Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='departmentName'
                value={departmentName}
                options={allDepartmentOptionsById}
                onChange={(option) => {
                  setDepartmentName(option);
                  departmentName.length < 0
                    ? setProjDeptError(true)
                    : setProjDeptError(false);
                }}
              />
              {projDeptError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Type
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='projectType'
                value={projectType}
                options={projectTypeOptions}
                onChange={(option) => {
                  setProjectType(option);
                  projectType.length < 0
                    ? setProjTypeError(true)
                    : setProjTypeError(false);
                }}
              />
              {projTypeError ? (
                <p className="error-padding" style={{ color: "red" }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Status
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='projectStatus'
                value={projectStatus}
                options={projectStatusOptions}
                onChange={(option) => {
                  setProjectStatus(option);
                  projectStatus.length < 0
                    ? setProjStatusError(true)
                    : setProjStatusError(false);
                }}
              />
              {projStatusError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="text-center mt-4">
        <Button className="primaryBtn " 
        onClick={() => { handlerSubmit() }}
        >
          <Icon.PersonAdd className='me-2' /> Create Project
        </Button>
      </Modal.Footer>
  </Modal>
    </>
   
  )
}

export default AddProjectModel
import React, { useEffect, useState } from 'react'
import { Container, InputGroup, Table, Modal, Button, Form, Row, Col, Spinner, Nav, Tab, Image } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';

import normal from "../../images/gif/frame-88.png"
import medium from "../../images/gif/Ripple-6.3s-200px.gif";
import immediate from "../../images/gif/Ripple-1s-200px (1).gif"
import Badge from 'react-bootstrap/Badge';
import { setFilterTasksData } from '../Common/AllTaskSlice';

import Pagination from '../Common/Pagination';
import { getEmployeeAllTask } from './EmpSlice';
import EmpTaskFilterModel from './Model/EmpTaskFilterModel';
import { setActiveToggle } from '../Common/AllTaskSlice';

import Lottie from 'react-lottie';
import * as animationData from '../../Assets/NoDataFound.json'
import DisplayHoursAndMinutes from '../Common/Convertor/DisplayHoursAndMinutes';

const EmployeeTask = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const toggle = [{ name: 'Pending', value: 'pending' }, { name: 'Completed', value: 'completed' }];
    const roleOfUser = ["manager", "humanResource", "employee", "contractual" , "intern", "teamlead"]
    const roleOfEmpUser = [ "employee", "contractual" , "intern"]

    const loginUserData = useSelector((state) => state?.loginData?.userData)
    const allTasks = useSelector(state => state?.employee?.EmployeeAllTask)

    const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);
    const currentToggle = useSelector((state) => state?.AllTasks?.ActiveToggle);

    const [show, setShow] = useState(false)
    const [showfilter, setShowFilter] = useState(false)
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    // const [totalPages, setTotalPages] = useState(allTasks?.noOfPages);
    const [item, setItem] = useState()
    // for edit task state
    const [showEdit, setShowEdit] = useState(false)
    const [showreview, setShowReview] = useState(false)

    const [showdeletemodal, setShowDeleteModal] = useState(false)

     // for filter tab value reset
    const [pendingFilterValues, setPendingFilterValues] = useState({});
    const [completedFilterValues, setCompletedFilterValues] = useState({});

    // for review modal
    const [taskId, setTaskId] = useState("")

     // for show data not found
    const [showNoDataFound, setShowNoDataFound] = useState(false);

    // for no data found true false
    const handleFilterTrue = () => {
        setShowNoDataFound(true)
    }

    const handleFilterFalse = ()=>{
        setShowNoDataFound(false)
    }


    const getALLTaskData = () => {
        const payload = {
            "empId": loginUserData?.empId,
            "taskPriority": "",
            "taskName": "",
            "departmentName": "",
            "departments": [],
            "projectName": "",
            "categoryOfName": "",
            "resource_id": "",
            "proposedStartDate": "",
            "proposedEndDate": "",
            "status": currentToggle,
            "resourceType": `${loginUserData.resourceType}`,
            "assignBy_Name": "",
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }
        dispatch(getEmployeeAllTask(payload))
    }

    useEffect(() => {
        getALLTaskData()
    }, [currentPage, docsPerPage, currentToggle])

    // function

    const handlerCloseFilterModel = () => setShowFilter(false)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

    };
    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1);
        setDocsPerPage(docsPageProp);
    }
    // for review function
    const handleShowReviewModal = (id) => {
        setTaskId(id)
        setShowReview(true)
    }

    // for delete modal
    const handleShowDeleteModal = (id) => {
        setTaskId(id)
        setShowDeleteModal(true)
    }

    // for edit function
    const handleShowEditModal = (id) => {
        setItem(id)
        setShowEdit(id)
    }

    // for status toggle change
  const handleToggleChange = (toggleValue) => {
    dispatch(setActiveToggle(toggleValue));
    if (toggleValue === 'pending') {
      setCompletedFilterValues({});
    } else {
      setPendingFilterValues({});
    }
  };

    const defaultOptions={
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      }

    return (
        <div className='TLAssignedTask outletPadding' data-aos='fade-up'>
            <Container>

                <EmpTaskFilterModel 
                    show={showfilter} 
                    close={handlerCloseFilterModel} 
                    handleFilterTrue = {handleFilterTrue}
                    handlerFilterFalse = {handleFilterFalse}
                    showNoDataFound={showNoDataFound}
                    taskStatus={currentToggle}
                    currentPage={currentPage}
                    docsPerPage={docsPerPage}
                    filterValues={
                    currentToggle === 'pending'
                    ? pendingFilterValues
                    : completedFilterValues
                }/>
                <Row className='d-flex justify-content-between align-items-center mb-3'>
                    <Col md={6}  className="d-flex justify-content-start align-items-center ">
                        {
                            !roleOfEmpUser.includes(loginUserData?.resourceType) && 
                            <Button className="primaryBtn btn-16 me-3" onClick={() => { setShow(true) }}>
                                <Icon.PlusCircle className="me-2" size={16} />
                                Create Task
                            </Button>
                        }
                        {allTasks?.data !== null || allTasks?.data?.length > 0 ? <Button
                        className={`${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'} mt-2 my-3 my-md-0`}
                            onClick={() => setShowFilter(true)} >
                            <Icon.Funnel className='me-2' size={17} />Filter
                        </Button> : ''}  
                    </Col>
                    <Col sm={12} md={6} className='d-flex justify-content-end align-items-center'>
                        <ButtonGroup aria-label="Basic example">
                        <Button
                            disabled={currentToggle === "pending" ? true : false}
                            variant={currentToggle === 'pending' ? 'warning' : 'outline-warning'} // Use 'warning' when active, 'light' otherwise
                            onClick={() => {handleToggleChange('pending');dispatch(setFilterTasksData([]));setShowNoDataFound(false)
                            // handleDocsPerPage(10);
                            }}
                        >
                            Pending
                        </Button>
                        <Button
                            disabled={currentToggle === "completed" ? true : false}
                            variant={currentToggle === 'completed' ? 'success' : 'outline-success'}
                            onClick={() => {handleToggleChange('completed');dispatch(setFilterTasksData([]));setShowNoDataFound(false)}}
                        >
                            Completed
                        </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                <div className="outer-wrapper mx-auto">
                {
                    allTasks?.data === null || allTasks?.data?.length === 0 ? 
                        <Lottie options={defaultOptions} weight={300} width={300}  className='img-fluid' style={{overflow:'hidden'}}/> 
                        : 
                        <div className="table-wrapper">
                        <table>
                            <thead>
                                <th>Sr No</th>
                                <th>Priority</th>
                                {currentToggle === "pending" && 
                                <th>Due(Days)</th>}
                                <th className='taskwidth'>Task Name</th>
                                <th>Project</th>
                                {
                                    roleOfEmpUser.includes(loginUserData?.resourceType) &&
                                    <th className='assignwidth'>Assign By</th>
                                }
                                {
                                    !roleOfEmpUser.includes(loginUserData?.resourceType) &&
                                    <th>Assign To</th>
                                }
                                <th>Date</th>
                                <th>Initial Hr</th>
                                {
                                    currentToggle === "completed" &&
                                    <> 
                                        <th>Actual Hr</th>
                                        <th>IDC</th> 
                                        <th>QWD</th> 
                                        <th>Slippage</th>
                                        <th>Efficiency</th>
                                        {
                                          !roleOfEmpUser.includes(loginUserData?.resourceType) &&
                                            <th>Review</th>
                                        }
                                    </>
                                }
                                <th>Status</th>
                                <th>Action</th>
                            </thead>
                            
                            {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                            <tbody>
                                {
                                allTasks?.data !== null && 
                                allTasks?.data !== undefined && 
                                allTasks?.data?.length > 0 ? (
                                (filterAllTasks?.data?.length > 0 
                                ? filterAllTasks?.data
                                : allTasks?.data
                                ).map((itm, index) => {
                                        const { taskName, taskPriority, taskDepartmentName, projectName, categoryOfName, assignBy_Name,
                                            resourceName, proposedStartDate, proposedEndDate, status, idc, qwd, slippage, efficiency, taskId,departmentName, initialEstimateHr,actualHours } = itm
                                        
                                        // for due days
                                        const currentDate = new Date();
                                        const endDate = new Date(itm.proposedEndDate);
                                        const endTimestamp = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
                                        const currentTimestamp = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

                                        let daysDifference = 0; 
                                        if (currentTimestamp > endTimestamp) {
                                            const differenceMillis = Math.abs(currentTimestamp - endTimestamp);
                                            daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
                                        }
                                        return (
                                            <tr key={Math.random() * 999999999}>
                                                <td>{currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>}</td>
                                                <td>
                                                    {taskPriority === "normal" ?
                                                        <img width={50} height={50} src={normal} alt='Normal' data-toggle="tooltip" title="Normal" />
                                                        :
                                                        taskPriority === "medium"
                                                            ?
                                                            <img width={50} height={50} src={medium} alt='Medium' data-toggle="tooltip" title="Medium" />
                                                            :
                                                            taskPriority === "immediate"
                                                                ?
                                                                <img width={50} height={50} src={immediate} alt='Immediate' data-toggle="tooltip" title="Immediate" />
                                                                : ''
                                                    }
                                                </td>
                                                {currentToggle === "pending" && 
                                                    <td>
                                                        <div className={`circle ${daysDifference < 5 ? 'green' : daysDifference < 15 ? 'orange' : 'red'}`} style = {{height:'35px',width:'35px',borderRadius:'55%',margin:'auto'}}>
                                                            <span className="number">{Math.floor(daysDifference)}</span>
                                                        </div>
                                                    </td>
                                                }

                                                <td>{taskName}</td>
                                                <td>{projectName}</td>
                                                {
                                                    roleOfUser.includes(loginUserData?.resourceType)
                                                    &&
                                                    <td>{assignBy_Name}</td>
                                                }
                                                {
                                                    !roleOfEmpUser.includes(loginUserData?.resourceType) &&
                                                    <td>{resourceName}</td>
                                                }
                                                <td>
                                                    {new Date(
                                                        proposedStartDate
                                                    ).toLocaleDateString("en-US", {
                                                        month: "long",
                                                        year: "numeric",
                                                    }) ===
                                                        new Date(proposedEndDate).toLocaleDateString("en-US", { month: "long", year: "numeric" })
                                                        ? `${new Date(
                                                            proposedStartDate
                                                        ).getDate()} - ${new Date(
                                                            proposedEndDate
                                                        ).getDate()} ${new Date(
                                                            proposedStartDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}`
                                                        : `${new Date(
                                                            proposedStartDate
                                                        ).getDate()} ${new Date(
                                                            proposedStartDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })} -  ${new Date(
                                                            proposedEndDate
                                                        ).getDate()} ${new Date(
                                                            proposedEndDate
                                                        ).toLocaleDateString("en-US", {
                                                            month: "long",
                                                            year: "numeric",
                                                        })}`}
                                                </td>
                                                <td><DisplayHoursAndMinutes value = {initialEstimateHr}/> </td>

                                                {
                                                    currentToggle === "completed" &&
                                                    <>
                                                        <td><DisplayHoursAndMinutes value = {actualHours}/> </td>
                                                        <td>{idc !== null && idc !== undefined ? idc.toFixed(2) : 0}</td>
                                                        <td>{qwd !== null && qwd !== undefined ? qwd.toFixed(2) : 0}</td>
                                                        <td>{slippage !== null && slippage !== undefined ? slippage.toFixed(2) : 0}</td>
                                                        <td>{efficiency !== null && efficiency !== undefined ? efficiency.toFixed(2) : 0}</td>
                                                        {
                                                             !roleOfEmpUser.includes(loginUserData?.resourceType) &&
                                                            <td>
                                                                {
                                                                    assignBy_Name === `${loginUserData?.firstName} ${loginUserData?.lastName}` &&
                                                                    currentToggle === "completed" ?
                                                                        <>
                                                                            {!idc && !qwd ? <div data-toggle="modal" style={{ color: "#292929" }}>
                                                                                <Button className="primaryBtn" style={{ padding: '4px 12px' }} onClick={() => { handleShowReviewModal(taskId) }}>
                                                                                    Review
                                                                                </Button>
                                                                            </div> :
                                                                                <div>
                                                                                    <Button className="primaryBtn" disabled>
                                                                                        Reviewed
                                                                                    </Button>
                                                                                </div>}
                                                                        </>
                                                                        :

                                                                        "___"

                                                                }
                                                            </td>
                                                        }
                                                    </>
                                                }

                                                <td className='text-capitalize'>
                                                    <Badge bg={status === "completed" ? "success p-1 text-white" : "warning p-1"} text="dark">{status}</Badge>
                                                </td>

                                                <td>
                                                    <span
                                                        data-toggle="tooltip"
                                                        title="view">
                                                        <Icon.Eye className='me-2' size={20} onClick={() => {
                                                            navigate(`/${loginUserData?.resourceType}/all-task/view-details`, {
                                                                state: { data: itm }
                                                            })
                                                        }} />
                                                    </span>
                                                    <>
                                                        {
                                                            assignBy_Name === `${loginUserData?.firstName} ${loginUserData?.lastName}`
                                                            &&
                                                            <>
                                                                <span
                                                                    data-toggle="tooltip"
                                                                    title="edit"
                                                                >
                                                                    <Icon.PencilSquare className='me-2' size={20} onClick={() => { handleShowEditModal(itm); }} />
                                                                </span>
                                                                <span
                                                                    data-toggle="tooltip"
                                                                    title="delete"
                                                                    style={{
                                                                        color: "#000000",
                                                                        fontSize: "20px",
                                                                    }}
                                                                >
                                                                    <Icon.Trash onClick={() => { handleShowDeleteModal(taskId) }} />
                                                                </span>
                                                            </>
                                                        }

                                                    </>

                                                </td>
                                            </tr>
                                        )
                                    })
                                    ):(
                                        <tr><td colSpan="18" className="text-center">No data available</td></tr>
                                    )}
                            </tbody>
                            )
                            :
                            <tr>
                            <td colSpan="16" className="text-center">No Task Available</td>
                            </tr>
                            }
                        </table>
                    </div>
                }
                    
                </div>

                {
                    allTasks?.data !== null || allTasks?.data?.length > 0 ?
                    <div className="pagination-wrapper p-0">
                        <Pagination 
                            currentPage={currentPage} 
                            totalPages={filterAllTasks?.data?.length > 0 ? filterAllTasks?.noOfPages :  allTasks?.noOfPages}
                            onPageChange={handlePageChange} 
                            onDocsPerPage={handleDocsPerPage} 
                            docsPerPage={docsPerPage}
                        />
                    </div>
                    : ''
                }
            </Container>
        </div >
    )
}

export default EmployeeTask
import axios from 'axios'
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { Container, Row, Table, Button, Form } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { getProjectData } from './AdminSlice';
import Badge from 'react-bootstrap/Badge';
import AddProjectModel from './Model/AddProjectModel';
import { EditProjectModel } from './Model/EditProjectModel';
import { DeleteProjectModel } from './Model/DeleteProjectModel';
import { FilterProjectModel } from './Model/FilterProjectModel';
import Pagination from '../Common/Pagination';

const Project = () => {

  const dispatch = useDispatch()

  const projects = useSelector(state => state.admin.projectData)
  const loginUserData = useSelector((state) => state.loginData.userData);

  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState()
  const [taskId, setTaskId] = useState("")
  const [showOpenAdd, setShowOpenAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showFilter, setShowFilter] = useState(false)


  const handlerGetAllProject = () => {
    const payload = {
      "projName": "",
      "departmentName": "",
      "clientName": "",
      "projectType": "",
      "categoryName": "",
      "status": "",
      "documentsPerPage": docsPerPage,
      "page": currentPage,
    }
    dispatch(getProjectData(payload))
  }

  useEffect(() => {
    handlerGetAllProject()
  }, [currentPage, docsPerPage])

  // for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  }

  const handleCloseAdd = () => {
    setShowOpenAdd(false)
    handlerGetAllProject()
  }

  const handleShowEditModal = (id) => {
    setTaskId(id)
    setItem(id)
    setShowEdit(id)
  }

  const handleCloseEditModel = () => {
    setShowEdit(false)
    handlerGetAllProject()
  }

  const handleShowDeleteModal = (id) => {
    setTaskId(id)
    setItem(id)
    setShowDelete(id)
  }

  const handleCloseDeleteModel = () => {
    setShowDelete(false)
    handlerGetAllProject()
  }

  const handleCloseFilter = () => {
    setShowFilter(false)
  }

  return (
    <div className="outletPadding">
      <Container>
        {
          showOpenAdd &&
          <AddProjectModel show={showOpenAdd} close={handleCloseAdd} />
        }
        {
          showEdit &&
          <EditProjectModel show={showEdit} close={handleCloseEditModel} taskId={taskId} item={item} userData={loginUserData} />
        }
        {
          showDelete &&
          <DeleteProjectModel show={showDelete} close={handleCloseDeleteModel} projectId={taskId} />
        }
        <FilterProjectModel show={showFilter} close={handleCloseFilter} />


        <ToastContainer />
        <div className="row m-3">
          <div className="col-sm-12 col-md-4 mt-4 d-flex justify-content-start ">
            <Button className="primaryBtn me-3"
              onClick={(() => setShowOpenAdd(true))}
            >
              <Icon.PlusCircle className="me-2" size={16} />
              Add Projects
            </Button>
          </div>
          <div className="col-sm-12 col-md-8 mt-4 d-flex justify-content-end">
            <Button
              className="primaryBtn"
              onClick={() => setShowFilter(true)}
            >
              <Icon.Funnel className='me-2' size={17} />Filter
            </Button>
          </div>
        </div>

        <div className="outer-wrapper mx-auto">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th className='assignwidth'>Project Name</th>
                  <th className='taskwidth'>Client Name</th>
                  <th className='assignwidth'>Category</th>
                  <th className='assignwidth'>Department</th>
                  <th className='assignwidth'>Project Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  projects?.data !== null &&
                    projects?.data !== undefined &&
                    projects?.data?.length > 0
                    ? projects?.data?.map((row, index) => {
                      return (
                        <tr style={{ textAlign: "left" }}>
                          <td>{currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>}</td>
                          <td>{row?.projName}</td>
                          <td>{row?.clientName}</td>
                          <td>{row?.categoryName}</td>
                          <td>{row?.departmentName}</td>
                          <td>{row?.projectType}</td>
                          <td>
                            <Badge bg={row?.status === "planned" ? "danger text-white p-1" :
                              row?.status === "in support" ? "warning p-1" :
                                "success p-1 text-white"} text="dark">
                              {row?.status}
                            </Badge>
                          </td>
                          <td className="action-wrapper">
                            <>
                              <span
                                data-toggle="tooltip"
                                title="edit">
                                <Icon.PencilSquare className='me-2' size={20}
                                  onClick={() => { handleShowEditModal(row); }}
                                />
                              </span>
                              <span
                                style={{ color: "#000000", fontSize: "20px" }}>
                                <Icon.Trash
                                  onClick={() => handleShowDeleteModal(row.projectId)}
                                />
                              </span>
                            </>
                          </td>
                        </tr>
                      );
                    })
                    : <tr><td colSpan={13} className='text-center'>No Task Available</td></tr>}
              </tbody>
            </table>
          </div>
        </div>
        <div className="pagination-wrapper p-0">
          <Pagination
            currentPage={currentPage}
            totalPages={projects?.noOfPages}
            onPageChange={handlePageChange}
            onDocsPerPage={handleDocsPerPage}
            docsPerPage={docsPerPage}
          />
        </div>
      </Container>
    </div>
  )
}

export default Project

import React, { useEffect, useState } from 'react';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import * as Icon from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import normal from '../../images/gif/frame-88.png';
import medium from '../../images/gif/Ripple-6.3s-200px.gif';
import immediate from '../../images/gif/Ripple-1s-200px (1).gif';
import Badge from 'react-bootstrap/Badge';
import CreateTaskModel from './Model/CreateTaskModel';
import { getAllTasks, setActiveToggle, setFilterTasksData } from './AllTaskSlice';
import Pagination from './Pagination';
import ReviewModel from './Model/AddReviewModel';
import FilterTaskModel from './Model/FilterTaskModel';
import EditTaskModel from './Model/EditTaskModel';
import DeleteTaskModel from './Model/DeleteTaskModel';
import Lottie from 'react-lottie';
import * as animationData from '../../Assets/NoDataFound.json';
import { CreateAdminTaskModel } from '../Admin/Model/CreateAdminTaskModel';
import DisplayHoursAndMinutes from './Convertor/DisplayHoursAndMinutes';

const ShowAllTask = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentToggle = useSelector((state) => state?.AllTasks?.ActiveToggle);
  const allTasks = useSelector((state) => state?.AllTasks?.AllTask);
  const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);
  const loginUserData = useSelector((state) => state?.loginData?.userData);

  const roleOfUser = [
    'manager',
    'humanResource',
    'employee',
    'contractual',
    'intern',
    'teamlead',
    'admin',
  ];
  const employeeRole = ["employee","contractual","intern"]

  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState('');

  // for edit task state
  const [showEdit, setShowEdit] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // for review modal
  const [taskId, setTaskId] = useState('');

  // for filter tab value reset
  const [pendingFilterValues, setPendingFilterValues] = useState({});
  const [completedFilterValues, setCompletedFilterValues] = useState({});
  const[rejectedFilterValues,setRejectedFilterValues] = useState({})

  // for show data not found
  const [showNoDataFound, setShowNoDataFound] = useState(false);

 // for no data found true false
  const handleFilterTrue = () => {
    setShowNoDataFound(true)
  }

  const handleFilterFalse = ()=>{
    setShowNoDataFound(false)
  }

  const getALLTaskData = () => {
    const payload = {
      empId:
        loginUserData?.resourceType === 'humanResource' ||
        loginUserData?.resourceType === 'admin'
          ? ''
          : loginUserData?.empId,
      taskPriority: '',
      taskName: '',
      departmentName: '',
      taskDepartmentName:'',
      departments:
        location.pathname === `/${loginUserData?.resourceType}/self-task`
          ? []
          : loginUserData?.department.map((item) => item.label),
      projectName: '',
      categoryOfName: '',
      resource_id: '',
      proposedStartDate: '',
      proposedEndDate: '',
      status: currentToggle,
      resourceType:
        location.pathname === `/${loginUserData?.resourceType}/self-task`
          ? 'employee'
          : loginUserData?.resourceType,
      assignBy_Name:
        loginUserData?.resourceType === 'teamlead'
          ? `${loginUserData?.firstName} ${loginUserData?.lastName}`
          : '',
      documentsPerPage: docsPerPage,
      page: currentPage,
    };
    dispatch(getAllTasks(payload))
  }


  useEffect(() => {
    getALLTaskData();
  }, [currentPage, docsPerPage, currentToggle]);

// for status toggle change
  const handleToggleChange = (toggleValue) => {
    dispatch(setActiveToggle(toggleValue));
    if (toggleValue === 'pending') {
      setCompletedFilterValues({});
    } else if(toggleValue === "completed") {
      setPendingFilterValues({});
    }else{
      setRejectedFilterValues({});
    }
  };


  const handleCloseCreateModel = () => {
    getALLTaskData();
    setShow(false);
  };

  const handleFilterModalClose = () => {
    setShowFilter(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  };

  const handleReviewModalClose = () => {
    setShowReview(false);
    setTaskId('');
    getALLTaskData();
  };
  const handleDeleteModalClose = () => {
    getALLTaskData();
    setShowDeleteModal(false);
    setTaskId('');
  };

  const handleEditModalClose = () => {
    setShowEdit(false);
    setItem('');
    getALLTaskData();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className='TLAssignedTask outletPadding' data-aos='fade-up'>
      <Container>
       <FilterTaskModel
          show={showFilter}
          close={handleFilterModalClose}
          handleFilterTrue = {handleFilterTrue}
          handlerFilterFalse = {handleFilterFalse}
          showNoDataFound={showNoDataFound}
          taskStatus={currentToggle}
          currentPage={currentPage}
          docsPerPage={docsPerPage}
          filterValues={
            currentToggle === 'pending'
              ? pendingFilterValues
              : currentToggle === "completed" ? completedFilterValues : rejectedFilterValues
          }   
        />

        {loginUserData?.resourceType === 'admin' ? (
          show && (
            <CreateAdminTaskModel
              show={show}
              close={handleCloseCreateModel}
              userData={loginUserData}
            />
          )
        ) : (
          show && (
            <CreateTaskModel
              show={show}
              close={handleCloseCreateModel}
              userData={loginUserData}
            />
          )
        )}

        {showReview && (
          <ReviewModel
            show={showReview}
            close={handleReviewModalClose}
            taskId={taskId}
          />
        )}

        {showDeleteModal && (
          <DeleteTaskModel
            show={showDeleteModal}
            close={handleDeleteModalClose}
            taskId={taskId}
          />
        )}

        {showEdit && (
          <EditTaskModel
            show={showEdit}
            close={handleEditModalClose}
            taskId={taskId}
            item={item}
            userData={loginUserData}
          />
        )}

        <Row className='d-flex justify-content-between align-items-center mb-4'>
          <Col md={6} className='d-flex justify-content-start align-items-center mb-3 mb-md-0'>
            {!employeeRole.includes(loginUserData?.resourceType) && (
              <Button className='primaryBtn me-3' onClick={() => setShow(true)}>
                <Icon.PlusCircle className='me-2' size={16} />
                Create Task
              </Button>
            )}
            <Button
              className={` ${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'}`}
              onClick={() => setShowFilter(true)}
            >
              <Icon.Funnel className='me-2' size={17} />
              Filter
            </Button>
            {/* handleDocsPerPage(10) */}
          </Col>
          <Col sm={12} md={6} className='d-flex justify-content-end align-items-center'>
             <ButtonGroup aria-label="Basic example">
              <Button
                disabled={currentToggle === "pending" ? true : false}
                variant={currentToggle === 'pending' ? 'warning' : 'outline-warning'} // Use 'warning' when active, 'light' otherwise
                onClick={() => {handleToggleChange('pending');dispatch(setFilterTasksData([]));setShowNoDataFound(false)
                }}
              >
                Pending
              </Button>
              <Button
                disabled={currentToggle === "completed" ? true : false}
                variant={currentToggle === 'completed' ? 'success' : 'outline-success'}
                onClick={() => {handleToggleChange('completed');dispatch(setFilterTasksData([]));setShowNoDataFound(false)}}
              >
                Completed
              </Button>
              <Button
                disabled={currentToggle === "rejected" ? true : false}
                variant={currentToggle === 'rejected' ? 'danger' : 'outline-danger'}
                onClick={() => {handleToggleChange('rejected');dispatch(setFilterTasksData([]));setShowNoDataFound(false)}}
              >
                Rejected
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <div className='outer-wrapper mx-auto'>
          <div className='table-wrapper'>
           <table>
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Priority</th>
                  {currentToggle === 'pending' && <th>Due(Days)</th>}
                  <th className='taskwidth'>Task Name</th>
                  <th>Department</th>
                  <th>Project</th>
                  <th className='max-width-th'>Category</th>
                  {roleOfUser.includes(loginUserData?.resourceType) && (
                    <th className='assignwidth'>Assign By</th>
                  )}
                  {loginUserData?.resourceType !== 'employee' && (
                    <th className='assignwidth'>Assign To</th>
                  )}
                  <th>Date</th>
                  <th>Initial Hr</th>
                  {currentToggle === 'completed' && (
                    <>
                      <th>Actual Hr</th>
                      <th>IDC</th>
                      <th>QWD</th>
                      <th>Slippage</th>
                      <th>Efficiency</th>
                    </>
                  )}
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                <tbody>
                  {allTasks?.data !== null &&
                  allTasks?.data !== undefined &&
                  allTasks?.data?.length > 0 ? (
                    (filterAllTasks?.data?.length > 0 
                      ? filterAllTasks?.data
                      : allTasks?.data
                    ).map((itm, index) => {
                      const {
                        taskName,
                        taskPriority,
                        taskDepartmentName,
                        projectName,
                        categoryOfName,
                        assignBy_Name,
                        resourceName,
                        proposedStartDate,
                        proposedEndDate,
                        status,
                        idc,
                        qwd,
                        slippage,
                        efficiency,
                        initialEstimateHr,
                        isRejected,
                        actualHours,
                      } = itm;

                      // For convert hours and minutes
                      const parts = initialEstimateHr?.toFixed(2).toString().split('.') || [];
                      const integerPart = parseInt(parts[0]) || 0;
                      const decimalPart = parseInt(parts[1]) || 0;
                      const hours = integerPart;
                      const minutes = Math.round((decimalPart / 100) * 60);
                                            
                      const currentDate = new Date();
                      const endDate = new Date(itm.proposedEndDate);
                      const endTimestamp = Date.UTC(endDate.getFullYear(),endDate.getMonth(),endDate.getDate());
                      const currentTimestamp = Date.UTC(currentDate.getFullYear(),currentDate.getMonth(),currentDate.getDate());

                      let daysDifference = 0;
                      if (currentTimestamp > endTimestamp) {
                        const differenceMillis = Math.abs(
                          currentTimestamp - endTimestamp
                        );
                        daysDifference = Math.ceil(
                          differenceMillis / (1000 * 60 * 60 * 24)
                        );
                      }

                      return (
                        <tr key={Math.random() * 999999999}>
                          <td>
                            {currentPage !== 1 ? (
                              <>
                                {' '}
                                {index + 1 + docsPerPage * (currentPage - 1)}
                              </>
                            ) : (
                              <>{index + 1}</>
                            )}
                          </td>
                          <td>
                            {taskPriority === 'normal' ? (
                              <img
                                width={50}
                                height={50}
                                src={normal}
                                alt='Normal'
                                data-toggle='tooltip'
                                title='Normal'
                              />
                            ) : taskPriority === 'medium' ? (
                              <img
                                width={50}
                                height={50}
                                src={medium}
                                alt='Medium'
                                data-toggle='tooltip'
                                title='Medium'
                              />
                            ) : taskPriority === 'immediate' ? (
                              <img
                                width={50}
                                height={50}
                                src={immediate}
                                alt='Immediate'
                                data-toggle='tooltip'
                                title='Immediate'
                              />
                            ) : (
                              ''
                            )}
                          </td>
                          {currentToggle === 'pending' && (
                            <td>
                              <div
                                className={`circle ${
                                  daysDifference < 5
                                    ? 'green'
                                    : daysDifference < 15
                                    ? 'orange'
                                    : 'red'
                                }`}
                                style={{
                                  height: '35px',
                                  width: '35px',
                                  borderRadius: '55%',
                                  margin: 'auto',
                                }}
                              >
                                <span className='number'>{Math.floor(daysDifference)}</span>
                              </div>
                            </td>
                          )}
                          <td>{taskName}</td>
                          <td>{taskDepartmentName}</td>
                          <td>{projectName}</td>
                          <td>{categoryOfName}</td>
                          {roleOfUser.includes(loginUserData?.resourceType) && (
                            <td>{assignBy_Name}</td>
                          )}
                          {loginUserData?.resourceType !== 'employee' && (
                            <td>{resourceName}</td>
                          )}
                          <td>
                            {new Date(proposedStartDate).toLocaleDateString(
                              'en-US',
                              {
                                month: 'long',
                                year: 'numeric',
                              }
                            ) ===
                            new Date(proposedEndDate).toLocaleDateString(
                              'en-US',
                              { month: 'long', year: 'numeric' }
                            )
                              ? `${new Date(
                                  proposedStartDate
                                ).getDate()} - ${new Date(
                                  proposedEndDate
                                ).getDate()} ${new Date(
                                  proposedStartDate
                                ).toLocaleDateString('en-US', {
                                  month: 'long',
                                  year: 'numeric',
                                })}`
                              : `${new Date(
                                  proposedStartDate
                                ).getDate()} ${new Date(
                                  proposedStartDate
                                ).toLocaleDateString('en-US', {
                                  month: 'long',
                                  year: 'numeric',
                                })} -  ${new Date(
                                  proposedEndDate
                                ).getDate()} ${new Date(
                                  proposedEndDate
                                ).toLocaleDateString('en-US', {
                                  month: 'long',
                                  year: 'numeric',
                                })}`}
                          </td>
                          <td>
                            <DisplayHoursAndMinutes value={initialEstimateHr} />{' '}
                          </td>
                          {currentToggle === 'completed' && (
                            <>
                              <td>
                                <DisplayHoursAndMinutes value={actualHours} />{' '}
                              </td>
                              <td>
                                {idc !== null && idc !== undefined
                                  ? idc.toFixed(2)
                                  : 0}
                              </td>
                              <td>
                                {qwd !== null && qwd !== undefined
                                  ? qwd.toFixed(2)
                                  : 0}
                              </td>
                              <td>
                                {slippage !== null && slippage !== undefined
                                  ? slippage.toFixed(2)
                                  : 0}
                              </td>
                              <td>
                                {efficiency !== null && efficiency !== undefined
                                  ? efficiency.toFixed(2)
                                  : 0}
                              </td>
                            </>
                          )}

                          <td className='text-capitalize'>
                            <Badge
                              bg={
                                status === 'rejected'
                                  ? 'danger p-1'
                                  : status === 'completed'
                                  ? 'success p-1 text-white'
                                  : 'warning text-dark p-1'
                              }
                            >
                              {status}
                            </Badge>
                          </td>
                          <td>
                            <span
                              data-toggle='tooltip'
                              title='view'
                              onClick={() => {
                                navigate(
                                  `/${loginUserData?.resourceType}/all-task/view-details`,
                                  {
                                    state: { data: itm },
                                  }
                                );
                              }}
                            >
                              <Icon.Eye className='me-2' size={20} />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                   ):(
                        <tr>
                          <td colSpan="18" className="text-center">No data available</td>
                        </tr>
                  )}
                </tbody>
                )
                  :
                 <tr>
                  <td colSpan="18" className="text-center">No Task Available</td>
                </tr>
                }
          </table>
          </div>
        </div>

        {allTasks?.data !== null || allTasks?.data?.length > 0 && filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
          <div className='pagination-wrapper p-0'>
            <Pagination
              currentPage={currentPage}
              totalPages={filterAllTasks?.data?.length > 0 ?filterAllTasks?.noOfPages :  allTasks?.noOfPages}
              onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage}
              docsPerPage={docsPerPage}
            />
          </div>
        ) : (
          ''
        )}
      </Container>
    </div>
  );
};

export default ShowAllTask;

import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import {Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import useGetDepartments from "../../Common/ReuseApi/useGetDepartments";

const EditEmployeeDetailsModel = (props) => {
  const { show, close, item, } = props;
  const ResourceType = [
    { value: 'superAdmin', label: 'Super Admin' },
    { value: 'admin', label: 'Admin' },
    { value: 'humanResource', label: 'HR' },
    { value: 'manager', label: 'Manager' },
    { value: 'teamlead', label: 'TeamLead' },
    { value: 'employee', label: 'Employee' },
    { value: 'intern', label: 'Intern' },
    { value: 'contractual', label: 'Contractual' }
  ]

  const [resourcetype, setResourceType] = useState("");
  const [department, setDepartment] = useState([]);
  const { allDepartmentOptions,allDepartmentOptionsById } = useGetDepartments();

  // for toast notification
  const toastSuccess = () => {
    toast.success("Update Successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  // for update usestate
  const [updatetask, setUpdateTask] = useState({
      "empId": "",
      "firstName": "",
      "lastName": "",
      "officialEmail": "",
      "workEmail": "",
      "phoneNo": "",
      "designation": "",
      "empSkill": "",
      "department": "",
      "experience": "",
      "resourceType":"",
  });

  // for error state
  const [empNameErr, setempNameErr] = useState(false);
  const [empEmailErr, setempEmailErr] = useState(false);
  const [empDeptErr, setempDeptErr] = useState(false);
  const [empdesignationErr, setempdesignationErr] = useState(false);
  const [empExpErr, setempExpErr] = useState(false);
  const [empResourceTypeErr, setempResourceTypeErr] = useState(false);

  useEffect(() => {
    setUpdateTask({
      empId: item?.empId,
      firstName: item?.firstName,
      lastName: item?.lastName,
      officialEmail: "",
      workEmail: item?.workEmail,
      phoneNo: "",
      designation: item?.designation,
      empSkill: "",
      // department: department?.value,
      department:({label: department?.label, value: department?.value}),
      experience: item?.experience,
      resourceType:resourcetype?.value
    });
    setDepartment({ label: item?.department?.map((itm) => itm?.label), value: item?.department?.map((itm) => itm?.value)})
    setResourceType({label:item?.resourceType , value:item?.item?.resourceType})
  }, [props]);

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUpdateTask({ ...updatetask, [name]: value });
  };

  // for validation
  const handlerValidate = () => {
    let FormValid = true;

    if (updatetask.firstName.length === 0) {
      FormValid = false;
      setempNameErr(true);
    }
    else if (updatetask.lastName.length === 0) {
      FormValid = false;
      setempNameErr(true);
    }
    else if (updatetask.workEmail.length === 0) {
      FormValid = false;
      setempExpErr(true);
    }
    else if (department.length === 0) {
      FormValid = false;
      setempDeptErr(true);
    }
     else if (updatetask.experience.length === 0) {
      FormValid = false;
      setempExpErr(true);
    }
    else if (updatetask.designation.length === 0) {
      FormValid = false;
      setempdesignationErr(true);
    }
    else if (resourcetype.length === 0) {
      FormValid = false;
      setempResourceTypeErr(true);
    }
    return FormValid;
  };

  // for update data function call
  const updateTask = async (e) => {
    e.preventDefault();
    setempNameErr(false);
    setempEmailErr(false);
    setempDeptErr(false);
    setempdesignationErr(false);
    setempExpErr(false);
    setempResourceTypeErr(false);
    if (handlerValidate()) {
        let upadatePayload = {
          empId: updatetask?.empId,
          firstName: updatetask?.firstName,
          lastName: updatetask?.lastName,
          officialEmail: "",
          workEmail: updatetask?.workEmail,
          phoneNo: "",
          designation: updatetask?.designation,
          empSkill: "",
          experience: updatetask?.experience,
          resourceType:resourcetype?.value,
          // department:department?.label,
          department:({label: department?.label, value: department?.value})
        }
        await axios.put(process.env.REACT_APP_BASE_URL + "/emp/updateEmployee", upadatePayload)
          .then((res) => {
            toastSuccess()
            setTimeout(() => {
              props.close()
            }, 1000)
          })
          .catch((err) => {
            console.log("Error from update api", err);
          })
      }
  }

  return (
    <div>
      <ToastContainer />
      <Modal show={show} onHide={close} size="lg" centered>
        <Modal.Header
          closeButton
          style={{
            color: "black",
            width: "100%",
            height: "50px",
            backgroundColor: "#F9D65F",
          }}
        >
          <Modal.Title><h5>Edit Employee</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
          <Row className="mt-3">
            <Col md={4}>
              <Form.Group controlId="formBasicName">
                <Form.Label>
                  First Name
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                placeholder="First Name"
                  name="firstName"
                  value={updatetask?.firstName}
                  onChange={(e) => {
                    handleInputs(e);
                    if (!e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      handleInputs(e);
                    } 
                    else if (e.target.value.length === 0) {
                      handleInputs(e);
                    }
                    updatetask?.firstName?.length <= 0
                      ? setempNameErr(true)
                      : setempNameErr(false);
                  }}
                />
                {empNameErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formBasicName">
                <Form.Label>
                  Last Name
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                placeholder="Last Name"
                  name="lastName"
                  value={updatetask?.lastName}
                  onChange={(e) => {
                    handleInputs(e);
                    if (!e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      handleInputs(e);
                    } 
                    else if (e.target.value.length === 0) {
                      handleInputs(e);
                    }
                    updatetask?.lastName?.length <= 0
                      ? setempNameErr(true)
                      : setempNameErr(false);
                  }}
                />
                {empNameErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
              >
                <Form.Label>
                  Email Id
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                  name="workEmail"
                  value={updatetask?.workEmail}
                  onChange={(e) => {
                    handleInputs(e);
                    updatetask?.workEmail?.length <= 0
                      ? setempNameErr(true)
                      : setempNameErr(false);
                  }}
                />
                {empEmailErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="emp-form-label fw-bold">
                  Department
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={department}
                  options={allDepartmentOptionsById}
                  onChange={(option) => {
                    setDepartment(option);
                    option.length === 0 ? setempDeptErr(true) : setempDeptErr(false);
                  }}
                />
                {empDeptErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>

             <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Experience
                </Form.Label>
               <Form.Control
                  name="experience"
                  value={updatetask?.experience}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[A-Za-z+@#$&%!~=*/\s/g/\-/?<>,"/]/
                      )
                    ) {
                      handleInputs(e);
                    } else if (e.target.value.length === 0) {
                      handleInputs(e);
                    }
                    updatetask?.experience?.length >= 0
                      ? setempExpErr(false)
                      : setempExpErr(true);
                  }}
                />
              </Form.Group>
              {empExpErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group controlId="formBasicName">
                <Form.Label>
                  designation
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Form.Control
                placeholder="Employee Name"
                  name="designation"
                  value={updatetask?.designation}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      handleInputs(e);
                    } else if (e.target.value.length === 0) {
                      handleInputs(e);
                    }
                    updatetask?.designation?.length <= 0
                      ? setempdesignationErr(true)
                      : setempdesignationErr(false);
                  }}
                />
                {empdesignationErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group
              >
                <Form.Label>
                  Resource Type
                  <span style={{ color: "red", fontWeight: "bold" }}>*</span>
                </Form.Label>
                <Select
                isMulti={false}
                isSearchable={true}
                value={resourcetype}
                options={ResourceType}
                onChange={(option) => {
                setResourceType(option);
                option.length === 0 ? setempResourceTypeErr(true) : setempResourceTypeErr(false);
                }}
              />
                {empResourceTypeErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>

           
          </Row>
        </Modal.Body>
        <Modal.Footer className="text-center mt-4">
          <Button
            className="primaryBtn"
            onClick={(e) => updateTask(e)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditEmployeeDetailsModel;

import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetAssignTo = (urlEndpoint, payload, departmentName, selftDept) => {
    const [allAssignToOptions, setAllAssignToOptions] = useState([])

    useEffect(() => {
        try {
            
            if (!departmentName?.label || selftDept?.includes(departmentName?.label)) {
                axios.post(process.env.REACT_APP_BASE_URL + urlEndpoint, payload )
                    .then((res) => {
                        const filterOption = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                            return ({ value: itm.empId, label: `${itm.firstName} ${itm.lastName}` })
                        })

                        if (filterOption?.length > 0) {
                            setAllAssignToOptions(filterOption)
                        }
                    })
                    .catch((err) => {
                        console.log("Error", err)
                    })
            } else {

                let data = {
                    departmentName: [departmentName?.label],
                };

                axios.post(process.env.REACT_APP_BASE_URL + '/department/getDepartmentTL', data)
                    .then((res) => {
                        const filterOption = (res.data.data).length > 0 && (res.data.data).map((itm) => {
                            return ({ value: itm.teamLeadId, label: itm.teamLeadName })
                        })

                        if (filterOption.length > 0) {
                            setAllAssignToOptions(filterOption)
                        }
                    })
                    .catch((err) => {
                        console.log("Error", err)
                    })
            }

        } catch (error) {
            console.log("Error", error)
        }
    }, [departmentName?.value])

    return { allAssignToOptions }
}

export default useGetAssignTo





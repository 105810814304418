import axios from 'axios'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export const AcceptModel = ({show, close, taskId}) => {
    const AcceptTaskOtherDepartment =async()=>{
        let data = {
            taskId:taskId
          }
          await axios.post(process.env.REACT_APP_BASE_URL + "/task/acceptTask",data)
          .then((res)=>{
            if(res?.data?.data?.isAccepted === true && res.data.status === 200){
            close()    
            }
          })
          .catch((err)=>{
            console.log("error from api",err);
          })
      }

  return (
    <Modal
       show={show}
        onHide={close}
        centered >
        <Modal.Header closeButton style={{ color: "black" }}>
          <Modal.Title>Accept Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: "black", fontSize: "15px" }}>
            Are You Sure To Accept Task?👎
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
          <Button style={{backgroundColor:"green"}} onClick={AcceptTaskOtherDepartment}>
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

import axios from 'axios'
import React, { useEffect, useState } from 'react';

const useGetAllCategories = () => {

    const [allCategoriesOptions, setAllCategoriesOptions] = useState([])

    useEffect(() => {
        try {
            axios.get(process.env.REACT_APP_BASE_URL + '/category/getAllCategory')
                .then((res) => {
                    const filterOption = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                         return ({ value: itm.categoryId, label: itm.categoryName })
                    })

                    if (filterOption?.length > 0) {
                        setAllCategoriesOptions(filterOption)
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }, [])



    return { allCategoriesOptions }
}

export default useGetAllCategories
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetTlAndEmp = () => {
    const [alltloption, setAllTLOption] = useState([])
    const [allempoption, setAllEmpOption] = useState([])

    useEffect(() => {
        try {
            axios.get(process.env.REACT_APP_NEW_BASE_URL + '/getLeadsAndEmployees')
                .then((res) => {
                    if (Array.isArray(res?.data?.leads) && res?.data?.leads?.length > 0) {
                        const filterTLOption = res.data?.leads?.map((itm) => {
                            return ({ value: itm.empId, label: itm.name })
                        })
                        setAllTLOption(filterTLOption)
                    }
                    if (Array.isArray(res?.data?.employees) && res?.data?.employees?.length > 0) {
                        const filterEmpOption = res?.data?.employees?.map((itm) => {
                            return ({ value: itm.empId, label: itm.name })
                        })
                        setAllEmpOption(filterEmpOption)
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }, [])

    return { alltloption, allempoption }
}

export default useGetTlAndEmp;

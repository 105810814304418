import React from 'react'

const DisplayHoursAndMinutes = ({ value }) => {
  if (typeof value === 'number' && !isNaN(value)) {
    const parts = value.toFixed(2).toString().split(".");
    const integerPart = parseInt(parts[0]);
    const decimalPart = parseInt(parts[1]);
    const hours = integerPart;
    const minutes = Math.round((decimalPart / 100) * 60);

    return (
      <>
        {hours} hr {minutes} min
      </>
    );
  } else {
    return "0";
  }
};

//  const parts = initialEstimateHr.toFixed(2).toString().split(".");
//  const integerPart = parseInt(parts[0]);
//  const decimalPart = parseInt(parts[1]);
//  const hours = integerPart;
//  const minutes = Math.round((decimalPart / 100) * 60);

export default DisplayHoursAndMinutes

import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetAllProjects = () => {

    const [allProjectOptions, setAllProjectOptions] = useState([])
    const [allProjectOptionsById, setAllProjectOptionsById] = useState([])

    useEffect(() => {
        try {
            axios.get(process.env.REACT_APP_BASE_URL + '/project/viewproject')
                .then((res) => {
                    const filterOption = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                        return ({ value: itm.projName, label: itm.projName })
                    })

                    const filterOptionById = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                        return ({ value: itm.projectId, label: itm.projName })
                    })

                    if (filterOption?.length > 0) {
                        setAllProjectOptions(filterOption)
                        setAllProjectOptionsById(filterOptionById)
                    }
                })
                .catch((err) => {
                    console.log("Error", err)
                })
        } catch (error) {
            console.log("Error", error)
        }
    }, [])



    return { allProjectOptions, allProjectOptionsById }
}

export default useGetAllProjects
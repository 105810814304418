import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Importing CSS -----
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';


// krupa manager css 06-07-2023 start*******************************
import "./Features/HR/HrStyle.css";


// Krupa Changes End *******************************************


// Importing Redux Dependacies -----
import { Provider } from 'react-redux';
import { persistor, store } from './Store';
import { PersistGate } from 'redux-persist/integration/react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
       <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();

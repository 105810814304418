import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Form, Row, Col,} from "react-bootstrap";
import { getResourceUtilization } from './AllTaskSlice';
import Pagination from './Pagination'
import { AssignNoTaskModel } from './Model/AssignNoTaskModel';
import * as Icon from 'react-bootstrap-icons';
import { FilterNoTaskModel } from './Model/FilterNoTaskModel';
import { FilterUtilizationModel } from './Model/FilterUtilizationModel';

const ResourceUtilization = () => {

    const dispatch = useDispatch()
    const loginUserData = useSelector((state) => state.loginData.userData);
    const deptNameArray = (loginUserData?.department).map((itm) => itm.label)
    const roleOfUser = ["teamlead","manager"]
    const ResourceUtilization = useSelector((state) => state?.AllTasks?.ResourceUtilization);

    // for notaskmodel
    const [shownotaskmodal, setShowNoTaskModal] = useState(false)
    const[notaskvalue,setNoTaskValue] = useState("")

    // for filter
     const [showFilter, setShowFilter] = useState(false)

    // for pagination state
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const getResourceUtilizationData = ()=>{
        const payload = {
            "date": "",
            "empId": "",
            "department": roleOfUser.includes(loginUserData.resourceType) ? deptNameArray : "",
            "designation": "",
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }
         dispatch(getResourceUtilization(payload))

    }
     useEffect(() => {
        getResourceUtilizationData()
    }, [currentPage,docsPerPage])

    // for filter
    const handleCloseFilter = () => {
    setShowFilter(false)
  }

    //----- Pagination ---------------
   
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1)
        setDocsPerPage(docsPageProp);
    }

    // for model open and close function
    const handleShowNoTaskModal = (id) => {
        setNoTaskValue(id)
        setShowNoTaskModal(true)
    }
    const handleNoTaskModalClose = () => {
        setShowNoTaskModal(false)
        setNoTaskValue("")
        getResourceUtilizationData()
        }
    return (
        <div className='noTask outletPadding' data-aos="fade-up">
            <Container>
                <AssignNoTaskModel show={shownotaskmodal} close={handleNoTaskModalClose} data={notaskvalue} userData={loginUserData} />
                 <FilterUtilizationModel show={showFilter} close={handleCloseFilter}/>
                <Row className='d-flex justify-content-end'>
                        <Col md={2} className="d-flex justify-content-end align-items-center">
                            <Button className="primaryBtn d-flex align-items-center" onClick={() => { setShowFilter(true) }} >
                                <Icon.Funnel className='me-2' size={17} />Filter
                            </Button>
                        </Col>
                    </Row>

                <div className="outer-wrapper mx-auto">
                    <div className="table-wrapper">
                    <table>
                        <thead>
                            <tr >
                                <th>Sr No</th>
                                <th>Employee ID</th>
                                <th className='assignwidth1'>Name</th>
                                <th className='assignwidth1'>Work Email</th>
                                <th className='assignwidth1'>Designation</th>
                                <th>Department</th>
                                <th>TotalEstimateHrs</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ResourceUtilization?.data !== null &&
                                ResourceUtilization?.data !== undefined &&
                                ResourceUtilization?.data?.length > 0 ?
                                ResourceUtilization?.data?.map((itm,index) => {
                                    const { firstName, lastName, workEmail,designation,department,empId,totalEstimateHrs} = itm

                                    // for hours and minute
                                    const parts = totalEstimateHrs?.toFixed(2).toString().split(".") || [];
                                    const integerPart = parseInt(parts[0]);
                                    const decimalPart = parseInt(parts[1]);
                                    const hours = integerPart;
                                    const minutes = Math.round((decimalPart / 100) * 60);
                                    return (
                                        <tr key={Math.random() * 999999999}>
                                            <td>{currentPage !== 1 ? (<> {index + 1 + 10 * (currentPage - 1)}</>) : <>{index + 1}</>}</td>
                                            <td>{empId}</td>
                                            <td>{firstName} {lastName}</td>
                                            <td>{workEmail}</td>
                                            <td>{designation}</td>
                                            <td>{department && Array.isArray(department) && department.map((itm) => itm?.label).join(' , ')}</td>
                                            <td>
                                                {`${hours} hr ${minutes} min`}
                                                {/* {totalEstimateHrs?totalEstimateHrs.toFixed(2):0} */}
                                            </td>
                                          <td>
                                                <div
                                                    data-toggle="modal">
                                                   <Button
                                                        className="primaryBtn my-1" style={{padding:'4px 12px'}}
                                                        onClick={() => { handleShowNoTaskModal(itm) }}>
                                                        <Icon.PersonAdd className='me-2'/>Assign
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan="18" className="text-center">No Data Available</td></tr>
                            }
                        </tbody>
                        </table>
                      </div>
                    </div>
          
                <Row className='justify-content-end'>
                    <Pagination currentPage={currentPage} totalPages={ResourceUtilization?.noOfPages}
                    onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} />
                </Row>
            </Container>

        </div>
    )
}

export default ResourceUtilization
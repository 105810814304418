import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import moment from "moment";
import { ToastContainer } from 'react-toastify';
import * as Icon from "react-bootstrap-icons";
import { useLocation } from 'react-router-dom';
import Pagination from '../Common/Pagination';
import { getAllReview } from './MngSlice';
import { MngReviewModel } from './Model/MngReviewModel';

const Review = () => {

    const dispatch = useDispatch()

    const location = useLocation();
    const routeName = location.pathname

    const loginUserData = useSelector((state) => state.loginData.userData)
    const reviewData = useSelector(state => state?.mngData?.mngAllEmpTaskReview)
    const filterAllTasks = useSelector((state) => state?.AllTasks?.AllFilterTasksData);
    
    const [showFilter, setShowFilter] = useState(false)
    const [docsPerPage, setDocsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    
    // for show data not found
    const [showNoDataFound, setShowNoDataFound] = useState(false);

    // state for search data
    const [searchTerm, setSearchTerm] = useState('');

    // for no data found true false
    const handleFilterTrue = () => {
        setShowNoDataFound(true)
    }

    const handleFilterFalse = ()=>{
        setShowNoDataFound(false)
    }


    const getReviewData = () => {

        const payload = {
            "empId": loginUserData?.empId,
            "assignBy_Name": "",
            "departments": (loginUserData?.department).map(item => item.label),
            "taskDepartmentName": "",
            "taskName": "",
            "projectName": "",
            "resource_id": "",
            "idcMin": "",
            "idcMax": "",
            "qwdMin": "",
            "qwdMax": "",
            "slippage": "",
            "efficiency": "",
            "reviewDate": "",
            "resourceType": loginUserData?.resourceType,
            "documentsPerPage": docsPerPage,
            "page": currentPage
        }
        dispatch(getAllReview(payload))
    }

    useEffect(() => {
        getReviewData()
    }, [currentPage, docsPerPage, routeName])

    const handlerCloseFilterModel = () => setShowFilter(false)

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);

    };

    const handleDocsPerPage = (docsPageProp) => {
        setCurrentPage(1)
        setDocsPerPage(docsPageProp);
    }

    return (
        <>
            <div className='outletPadding' data-aos="fade-up">
                <Container>
                    <MngReviewModel 
                        show={showFilter} 
                        close={handlerCloseFilterModel} 
                        handleFilterTrue = {handleFilterTrue}
                        handlerFilterFalse = {handleFilterFalse}
                        showNoDataFound={showNoDataFound}
                        currentPage={currentPage}
                        docsPerPage={docsPerPage}
                    />
                    <ToastContainer />
                    <Row className='d-flex justify-content-end'>
                        <Col md={2} className="d-flex justify-content-end align-items-center">
                            <Button  className={` ${filterAllTasks?.data?.length > 0 || showNoDataFound  ? 'activeBtn': 'primaryBtn'}`} onClick={() => { setShowFilter(true) }}>
                                <Icon.Funnel className='me-2' size={17} />Filter
                            </Button>
                        </Col>
                    </Row>


                    <div className='outer-wrapper mx-auto'>
                        <div className='table-wrapper'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>Project Name</th>
                                        <th className='taskwidth'>Task Name</th>
                                        <th className='assignwidth'>Resource Name</th>
                                        <th>Department</th>
                                        <th>IDC</th>
                                        <th>QWD</th>
                                        <th>Slippage</th>
                                        <th>Efficiency</th>
                                        <th>Review Date</th>
                                    </tr>
                                </thead>
                                {filterAllTasks?.data?.length > 0 || !showNoDataFound ? (
                                 <tbody>
                                    {
                                        reviewData?.data !== null &&
                                            reviewData?.data !== undefined &&
                                            reviewData?.data?.length > 0 ? (
                                            (filterAllTasks?.data?.length > 0 
                                            ? filterAllTasks?.data
                                            : reviewData?.data
                                            ).map((itm, index) => {
                                                const { taskName, reviewDate, taskDepartmentName, projectName, resourceName,
                                                    idc, qwd, slippage, efficiency } = itm
                                                return (
                                                    <tr key={Math.random() * 999999999}>
                                                        <td> {currentPage !== 1 ? (
                                                            <>
                                                                {index + 1 + docsPerPage * (currentPage - 1)}
                                                            </>
                                                        ) : (
                                                            <>{index + 1}</>
                                                        )}</td>
                                                        <td>{projectName}</td>
                                                        <td style={{ flexWrap: "wrap" }}>{taskName}</td>
                                                        <td>{resourceName}</td>

                                                        {/* <td>{resourceName}</td> */}
                                                        <td>{taskDepartmentName}</td>
                                                        <td>{idc !== null && idc !== undefined ? idc.toFixed(2) : 0}</td>
                                                        <td>{qwd !== null && qwd !== undefined ? qwd.toFixed(2) : 0}</td>
                                                        <td>{slippage !== undefined && slippage !== null ? slippage.toFixed(2) : 0}</td>
                                                        <td>{efficiency !== undefined && efficiency !== null ? efficiency.toFixed(2) : 0}</td>
                                                        <td>{moment(reviewDate).format("DD-MM-YYYY")}</td>
                                                    </tr>
                                                )
                                            })
                                            ):(
                                                    <tr>
                                                    <td colSpan="18" className="text-center">No data available</td>
                                                    </tr>
                                            )}
                                 </tbody>
                                )
                                :
                                <tr>
                                <td colSpan="16" className="text-center">No Task Available</td>
                                </tr>
                                }
                            </table>
                        </div>
                    </div>

                </Container>
                
                 {
                    reviewData?.data !== null || reviewData?.data?.length > 0 ?
                    <div className="pagination-wrapper p-0">
                        <Pagination 
                        currentPage={currentPage} 
                        totalPages={filterAllTasks?.data?.length > 0 ?filterAllTasks?.noOfPages : reviewData?.noOfPages}
                        onPageChange={handlePageChange} 
                        onDocsPerPage={handleDocsPerPage} 
                        docsPerPage={docsPerPage}
                        />
                    </div>
                    : ''
                }
            </div>
        </>
    )
}

export default Review
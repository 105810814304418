import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, InputGroup, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { getAssignTaskFilterOptions, getAllAssignTask, setFilterTasksData} from '../AllTaskSlice';
import axios from 'axios';

export const FilterAssignTaskModel = ({ show, close,handleFilterTrue, handlerFilterFalse, showNoDataFound,taskStatus, filterValues,currentPage, docsPerPage  }) => {

  const dispatch = useDispatch()

  const loginUserData = useSelector((state) => state.loginData.userData)
  const allDropdownOption = useSelector(state => state?.AllTasks?.FilterAssignTaskFilterData)
  const currentToggle = useSelector((state) => state?.AllTasks?.ActiveToggle);

  const [projectName, setProjectName] = useState("")
  const [departmentName, setDepartmentName] = useState("")
  const [taskName, setTaskName] = useState("")
  const [category, setCategory] = useState("")
  const [assignByName, setAssignByName] = useState("")
  const [resourceId, setResourceId] = useState("")
  const [proposedStartDate, setProposedStartDate] = useState()
  const [proposedEndDate, setProposedEndDate] = useState()
  const [taskPriority, setTaskPriority] = useState("")
  const [idcMin, setIDCMin] = useState("")
  const [idcMax, setIDCMax] = useState("")
  const [qwdMin, setQWDMin] = useState("")
  const [qwdMax, setQWDMax] = useState("")
  const [slippage, setSlippage] = useState("")
  const [efficiency, setEfficiency] = useState("")


   // for handler submit
  const handleFilterSubmit = () => {
    if (
      projectName === "" &&
      departmentName === "" &&
      taskName === "" &&
      category === "" &&
      assignByName === "" &&
      resourceId === "" &&
      proposedStartDate === "" &&
      proposedEndDate === "" &&
      taskPriority === "" &&
      idcMin === "" &&
      idcMax === "" &&
      qwdMin === "" &&
      qwdMax === "" &&
      slippage === "" &&
      efficiency === ""
    ) {
      dispatch(setFilterTasksData([]));
      handlerFilterFalse();
    } else {
      getAssignTask();
    }
    close();
  };

  // for filter
  const clearFilter = () => {
    setProjectName("")
    setDepartmentName("")
    setTaskName("")
    setAssignByName("")
    setResourceId("")
    setProposedStartDate("")
    setProposedEndDate("")
    setTaskPriority("")
    setIDCMin("")
    setIDCMax("")
    setQWDMin("")
    setQWDMax("")
    setSlippage("")
    setEfficiency("")
  }

  useEffect(() => {
    dispatch(setFilterTasksData([]))
    if (filterValues) {
      setProjectName(filterValues.projectName || "");
      setDepartmentName(filterValues.departmentName || "");
      setTaskName(filterValues.taskName || "");
      setAssignByName(filterValues.assignByName || "")
      setResourceId(filterValues.resourceId || "")
      setProposedStartDate(filterValues.setProposedStartDate || "")
      setProposedEndDate(filterValues.setProposedEndDate || "")
      setTaskPriority(filterValues.taskPriority || "")
      setIDCMin(filterValues.idcMin || "")
      setIDCMax(filterValues.idcMax || "")
      setQWDMin(filterValues.qwdMin || "")
      setQWDMax(filterValues.qwdMax || "")
      setSlippage(filterValues.slippage || "")
      setEfficiency(filterValues.efficiency || "")
    }
  }, [filterValues]);

   const getAssignTask = async() => {
    const payload = {
      "assignBy_Name":  `${loginUserData?.firstName} ${loginUserData?.lastName}`,
      "taskPriority": taskPriority ? taskPriority.value : "",
      "taskName": taskName ? taskName.value : "",
      "department":departmentName ? departmentName.value : "",
      "departmentName":  (loginUserData?.department).map(item => item.label),
      "projectName": projectName ? projectName.value : "",
      "resource_id": resourceId ? resourceId.value : "",
      "proposedStartDate": proposedStartDate || " " ? proposedStartDate : "",
      "proposedEndDate": proposedEndDate || " " ? proposedEndDate : "",
      "idcMin": idcMin === "" ? "0" : idcMin.value,
      "idcMax": idcMax === "" ? "10" : idcMax.value,
      "qwdMin": qwdMin === "" ? "0" : qwdMin.value,
      "qwdMax": qwdMax === "" ? "10" : qwdMax.value,
      "slippage": slippage ? slippage.value : "",
      "efficiency": efficiency ? efficiency.value : "",
      "status": currentToggle,
      "documentsPerPage": docsPerPage ? docsPerPage : 10,
      "page": currentPage ? currentPage : 1
    }
    // dispatch(getAllAssignTask(payload))
    await axios.post(process.env.REACT_APP_NEW_BASE_URL + '/assignedToOtherDeptTask', payload)
      .then((response) => {
        dispatch(setFilterTasksData(response?.data))
        handleFilterTrue()
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

   useEffect(() => {
    if (showNoDataFound) {
      getAssignTask()
    }

  }, [currentPage, docsPerPage])

  useEffect(() => {
    // let payload = {}
    const payload = {
        "assignBy_Name":  `${loginUserData?.firstName} ${loginUserData?.lastName}`,
        "departmentName": (loginUserData?.department).map(item => item.label),
        "status": currentToggle,
      }
      dispatch(getAssignTaskFilterOptions(payload))
  }, [show])


  

 

  const IdcAndQwdOption = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
  ]


  return (
    <Modal
      show={show}
      onHide={close}
      style={{ color: "black" }}
      size="lg" centered
    >
      <Modal.Header closeButton>
        <h5>Filter</h5>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label className="mt-3 fw-bold">
                Project
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.data?.projects
                }
                value={projectName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Project name"
                onChange={(option) => { setProjectName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </Col>

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label className="mt-3 fw-bold">
                Department Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.data?.departmentNames
                }
                value={departmentName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Department Name"
                onChange={(option) => { setDepartmentName(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </Col>

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label className="emp-form-label mt-3 fw-bold">
                Assign To
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  allDropdownOption !== null &&
                  allDropdownOption !== undefined &&
                  allDropdownOption?.data?.assignTo
                }
                value={resourceId}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Resource Name"
                onChange={(option) => { setResourceId(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </Col>

          {
            taskStatus === "completed" ?
              <>
                <Col md={3} className='mb-3'>
                  <Form.Group>
                    <Form.Label> Min IDC </Form.Label>
                    <Select
                      isMulti={false}
                      options={IdcAndQwdOption}
                      value={idcMin}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Min IDC"
                      onChange={(option) => { setIDCMin(option) }}
                      isSearchable={true} />
                  </Form.Group>
                </Col>

                <Col md={3} className='mb-3'>
                  <Form.Group>
                    <Form.Label> Max IDC </Form.Label>
                    <Select
                      isMulti={false}
                      options={IdcAndQwdOption}
                      value={idcMax}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Max IDC"
                      onChange={(option) => { setIDCMax(option) }}
                      isSearchable={true} />
                  </Form.Group>
                </Col>

                <Col md={3} className='mb-3'>
                  <Form.Group>
                    <Form.Label> Min QWD </Form.Label>
                    <Select
                      isMulti={false}
                      options={IdcAndQwdOption}
                      value={qwdMin}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Min QWD"
                      onChange={(option) => { setQWDMin(option) }}
                      isSearchable={true} />
                  </Form.Group>
                </Col>

                <Col md={3} className='mb-3'>
                  <Form.Group>
                    <Form.Label> Max QWD </Form.Label>
                    <Select
                      isMulti={false}
                      options={IdcAndQwdOption}
                      value={qwdMax}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Max QWD"
                      onChange={(option) => { setQWDMax(option) }}
                      isSearchable={true} />
                  </Form.Group>
                </Col>
              </>
              :
              ""
          }

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label> Start Date </Form.Label>
              <InputGroup>
                <Form.Control type="date" onKeyDown={(event) => event.preventDefault()}
                  name="proposedStartDate" value={proposedStartDate} min={proposedEndDate}
                  onChange={(e) => setProposedStartDate(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
          </Col>

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label> End Date </Form.Label>
              <InputGroup>
                <Form.Control type="date" onKeyDown={(event) => event.preventDefault()}
                  name="proposedEndDate" value={proposedEndDate} min={proposedStartDate}
                  onChange={(e) => setProposedEndDate(e.target.value)}
                  disabled={!proposedStartDate} />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
        <Button className="primaryBtn" onClick={() => { handleFilterSubmit() }}>Submit </Button>
      </Modal.Footer>
    </Modal>
  )
}

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const initialState = {
  projects: '',
  loading: 'idle', // idle, pending, failed
};

export const getProjects = createAsyncThunk("getProjectsDataPromise", async (data, {rejectWithValue})=>{
  const response = await fetch(process.env.REACT_APP_BASE_URL + '/task/getProjectByTasksAndEmpId',{
  method:"POST",
  headers:{"Content-Type":"application/json"},
  body:JSON.stringify(data)
  })
  try{
      const result = await response.json()
      return result
  }catch(error){
      return rejectWithValue(error);

  }
}
)

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers:{
    setProjects(state, action){
      state.projects = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getProjects.pending, (s, a) => {
        console.log('inside loading');
        s.loading = 'pending';
      })
      .addCase(getProjects.rejected, (s, a) => {
        s.loading = 'idle';
        console.log('inside failed');
        alert('Failed');
      })
      .addCase(getProjects.fulfilled, (s, a) => {
        console.log("a--->",a)
        s.projects = a.payload.data;
        console.log('inside fulfilled');
        s.loading = 'success';
      });
  },
});

export const {setProjects} = projectSlice.actions;
export default projectSlice.reducer;
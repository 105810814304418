import React, { useEffect, useState } from 'react'
import { Container, Row, Table, Button, Form } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import { getClientData } from './AdminSlice';
import Badge from 'react-bootstrap/Badge';
import AddProjectModel from './Model/AddProjectModel';
import { EditProjectModel } from './Model/EditProjectModel';
import DeleteTaskModel from '../Common/Model/DeleteTaskModel';
import CreateClientModel from './Model/CreateClientModel';
import { DeleteClientModel } from './Model/DeleteClientModel';
import { EditClientModel } from './Model/EditClientModel';
import { FilterClientModel } from './Model/FilterClientModel';
import Pagination from '../Common/Pagination';

const Client = () => {

  const dispatch = useDispatch()
  const clientAllData = useSelector(state => state?.admin?.clientData)
  const loginUserData = useSelector((state) => state.loginData.userData);


  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [item, setItem] = useState()
  const [taskId, setTaskId] = useState("")
  const [showOpenAdd, setShowOpenAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [showFilter, setShowFilter] = useState(false)


  // for get all clients data
  const handlerGetAllClient = () => {
    const payload = {
      "clientName": "",
      "contactNo": "",
      "email": "",
      "company": "",
      "address": "",
      "details": "",
      "status": "",
      "documentsPerPage": docsPerPage,
      "page": currentPage
    }
    dispatch(getClientData())
  }
  useEffect(() => {
    handlerGetAllClient()
  }, [currentPage, docsPerPage])

  // for pagination
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

  };
  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1);
    setDocsPerPage(docsPageProp);
  }

  // for open create model
  const handleCloseAdd = () => {
    setShowOpenAdd(false)
    handlerGetAllClient()
  }

  // for edit model
  const handleShowEditModal = (id) => {
    setTaskId(id)
    setItem(id)
    setShowEdit(id)
  }

  const handleCloseEditModel = () => {
    setShowEdit(false)
    handlerGetAllClient()
  }

  // for delete model
  const handleShowDeleteModal = (id) => {
    setTaskId(id)
    setItem(id)
    setShowDelete(id)
  }

  const handleCloseDeleteModel = () => {
    setShowDelete(false)
    handlerGetAllClient()
  }

  // for filter model
  const handleCloseFilter = () => {
    setShowFilter(false)
  }


  return (
    <div className="outletPadding">
      <Container>
        {
          showOpenAdd &&
          <CreateClientModel show={showOpenAdd} close={handleCloseAdd} />
        }
        {
          showEdit &&
          <EditClientModel show={showEdit} close={handleCloseEditModel} taskId={taskId} item={item} userData={loginUserData} />
        }
        {
          showDelete &&
          <DeleteClientModel show={showDelete} close={handleCloseDeleteModel} clientId={taskId} />
        }
        <FilterClientModel show={showFilter} close={handleCloseFilter} />

        <ToastContainer />
        <div className="row m-3">
          <div className="col-sm-12 col-md-4 mt-4 d-flex justify-content-start ">
            <Button className="primaryBtn me-3"
              onClick={(() => setShowOpenAdd(true))}
            >
              <Icon.PlusCircle className="me-2" size={16} />
              Add Client
            </Button>
          </div>
          <div className="col-sm-12 col-md-8 mt-4 d-flex justify-content-end">
            <Button
              className="primaryBtn"
              onClick={() => setShowFilter(true)}
            >
              <Icon.Funnel className='me-2' size={17} />Filter
            </Button>
          </div>
        </div>

        <div className="outer-wrapper mx-auto">
          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th className='assignwidth'>Client Name</th>
                  <th className='assignwidth'>Company</th>
                  <th>Contact No</th>
                  <th className='assignwidth'>Email</th>
                  <th>Details</th>
                  <th className='assignwidth'>Address</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  clientAllData?.data !== null &&
                    clientAllData?.data !== undefined &&
                    clientAllData?.data?.length > 0
                    ? clientAllData?.data?.map((itm, index) => {
                      const { clientName, company, contactNo, email, details, address, status, clientId } = itm
                      return (
                        <tr style={{ textAlign: "left" }}>
                          <td>{currentPage !== 1 ? (<> {index + 1 + docsPerPage * (currentPage - 1)}</>) : <>{index + 1}</>}</td>
                          <td>{clientName}</td>
                          <td>{company}</td>
                          <td>{contactNo}</td>
                          <td>{email}</td>
                          <td>{details}</td>
                          <td>{address}</td>
                          <td>
                            <Badge bg={status === "active" ? "success text-white p-1" :
                              status === "inactive" ? "warning p-1 text-dark" : ''}>
                              {status}
                            </Badge>
                          </td>
                          <td className="action-wrapper">
                            <>
                              <span
                                data-toggle="tooltip"
                                title="edit">
                                <Icon.PencilSquare className='me-2' size={20}
                                  onClick={() => { handleShowEditModal(itm); }}
                                />
                              </span>
                              <span
                                style={{ color: "#000000", fontSize: "20px" }}>
                                <Icon.Trash
                                  onClick={() => handleShowDeleteModal(clientId)}
                                />
                              </span>
                            </>
                          </td>
                        </tr>
                      );
                    })
                    : <tr><td colSpan={13} className='text-center'>No Task Available</td></tr>}
              </tbody>
            </table>
          </div>
        </div>
        {
          clientAllData?.data !== null || clientAllData?.data?.length > 0 ?
            <div className="pagination-wrapper p-0">
              <Pagination
                currentPage={currentPage}
                totalPages={clientAllData?.noOfPages}
                onPageChange={handlePageChange}
                onDocsPerPage={handleDocsPerPage}
                docsPerPage={docsPerPage}
              />
            </div>
            : ''
        }
      </Container>
    </div>
  )
}

export default Client

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import moment from "moment";
import { ToastContainer } from 'react-toastify';
import * as Icon from "react-bootstrap-icons";
// import { FilterReviewModel } from './Model/FilterReviewModel';
import Pagination from '../Common/Pagination';
import { getSelfReview } from './AdminSlice';
import { SelfReviewModel } from '../Common/Model/SelfReviewModel';

const AdminSelfReview = () => {

  const dispatch = useDispatch()

  const loginUserData = useSelector((state) => state.loginData.userData)
  const selfReviewData = useSelector(state => state?.TLAssignedData?.TlSelfReview)

  const [docsPerPage, setDocsPerPage] = useState(10);
  const [showFilter, setShowFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchTerm, setSearchTerm] = useState('');

  const getReviewData = () => {
    const empPayload = {
      "empId": loginUserData?.empId,
      "assignBy_Name": "",
      "departments": [],
      "taskName": "",
      "projectName": "",
      "resource_id": "",
      "idc": "",
      "qwd": "",
      "slippage": "",
      "efficiency": "",
      "reviewDate": "",
      "resourceType": "",
      "documentsPerPage": docsPerPage,
      "page": currentPage
    }
    dispatch(getSelfReview(empPayload))
  }

  useEffect(() => {
    getReviewData()
  }, [currentPage, docsPerPage])

  const handlerCloseFilterModel = () => setShowFilter(false)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

  };

  const handleDocsPerPage = (docsPageProp) => {
    setCurrentPage(1)
    setDocsPerPage(docsPageProp);
  }

  return (
    <>
      <div className='outletPadding' data-aos="fade-up">
        <Container>
          <SelfReviewModel show={showFilter} close={handlerCloseFilterModel} />
          <ToastContainer />
          <Row className='d-flex justify-content-end'>
            {/* <Col md={3} className="mt-3 d-flex justify-content-start align-items-center">
              <Form.Control type="text" placeholder="search..." onChange={(e) => setSearchTerm(e.target.value)} />
            </Col> */}
            <Col md={2} className="d-flex justify-content-end align-items-center">
              <Button className="primaryBtn d-flex align-items-center" onClick={() => { setShowFilter(true) }}>
                <Icon.Funnel className='me-2' size={17} />Filter
              </Button>
            </Col>
          </Row>


          <div className='outer-wrapper mx-auto'>
            <div className='table-wrapper'>
              <table>
                <thead>
                  <tr>
                    <th>Sr.No</th>
                    <th>Project Name</th>
                    <th className='taskwidth'>Task Name</th>
                    <th className='assignwidth'>Assign By</th>
                    <th>Department</th>
                    <th>IDC</th>
                    <th>QWD</th>
                    <th>Slippage</th>
                    <th>Efficiency</th>
                    <th>Review Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    selfReviewData?.data !== null &&
                      selfReviewData?.data !== undefined &&
                      selfReviewData?.data?.length > 0 ?
                      selfReviewData?.data?.map((itm, index) => {
                        const { taskName, reviewDate, departmentName, taskDepartmentName, projectName,
                          categoryOfName, assignBy_Name, resourceName,
                          idc, qwd, slippage, efficiency } = itm
                        return (
                          <tr key={Math.random() * 999999999}>
                            <td> {currentPage !== 1 ? (
                              <>
                                {index + 1 + docsPerPage * (currentPage - 1)}
                              </>
                            ) : (
                              <>{index + 1}</>
                            )}</td>
                            <td>{projectName}</td>
                            <td style={{ flexWrap: "wrap" }}>{taskName}</td>
                            <td>{assignBy_Name}</td>
                            <td>{departmentName}</td>
                            <td>{idc !== null && idc !== undefined ? idc.toFixed(2) : 0}</td>
                            <td>{qwd !== null && qwd !== undefined ? qwd.toFixed(2) : 0}</td>
                            <td>{slippage !== null && slippage !== undefined ? slippage.toFixed(2) : 0}</td>
                            <td>{efficiency !== null && efficiency !== undefined ? efficiency.toFixed(2) : 0}</td>
                            <td>{moment(reviewDate).format("DD-MM-YYYY")}</td>
                          </tr>
                        )
                      })
                      :
                      <tr><td colSpan={13} className='text-center'>No Data Found</td></tr>
                  }
                </tbody>
              </table>
            </div>
          </div>

        </Container>
      </div>

      <div className="pagination-wrapper">
        <Pagination currentPage={currentPage} totalPages={selfReviewData?.noOfPages}
          onPageChange={handlePageChange} onDocsPerPage={handleDocsPerPage} />
      </div>
    </>
  )
}

export default AdminSelfReview
import React, {useEffect, useState } from 'react'
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import Select from "react-select";
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import useGetDepartments from '../../Common/ReuseApi/useGetDepartments';
import useGetAllProjects from '../../Common/ReuseApi/useGetAllProjects';
import useGetAllCategories from '../../Common/ReuseApi/useGetAllCategories';
import useGetAssignTo from '../../Common/ReuseApi/useGetAssignTo';
import { setActiveKey } from '../../Common/ActiveKeySlice';

export const CreateAdminTaskModel = ({show, close, userData }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const taskPriorityOptions = [{ value: 'normal', label: 'Normal' }, { value: 'medium', label: 'Medium' }, { value: 'immediate', label: 'Immediate' }];
  
    const loginUserData = useSelector((state) => state.loginData.userData)
    const userDataDept = loginUserData.department.map((itm) => { return itm.label })
  
    const AddTaskSuccess = (res) => {
      if (res?.assignBy_Name !== res?.resourceName && res?.departmentName === userDataDept) {
        toast.success("Assigned Task Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:1000,
        });
      }
      else if (res?.assignBy_Name !== res?.resourceName && res?.departmentName !== userDataDept) {
        toast.success("Assigned Task Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:1000,
        });
      }
    };
  
    const AlreadyTaskAdd = () => {
      toast.error("Task Already Assigned", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
    };
  
    const EstimateHr = () =>{
      toast.error("Initial Estimate hr can not be 0.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose:1000,
      });
    }
  
    const [addtask, setAddTask] = useState({
      taskName: "",
      taskdescription: "",
      proposedstartdate: "",
      proposedenddate: "",
      initialEstimateHr: "",
      logStatus: {
        status: "",
        resource_id: "",
        resourceName: "",
      },
    });
  
    const [allAssignToOptions,setAllAssignToOptions] = useState([])
    const [taskpriority, setTaskpriority] = useState("")
    const [departmentName, setDepartmentName] = useState("")
    const [projectname, setProjectname] = useState("")
    const [categoryname, setCategoryname] = useState("")
    const [assignto, setAssignto] = useState("")
    const [proposedStartDate, setProposedStartDate] = useState("")
    const [proposedEndDate, setProposedEndDate] = useState("")
    const [initialHr, setInitialHr] = useState("")
  
    // for error handling
    const [taskNameErr, setTaskNameErr] = useState(false)
    const [taskPriorityErrr, setTaskPriorityErr] = useState(false)
    const [taskDescErr, setTaskDescErr] = useState(false)
    const [tastDeptErr, setTaskDeptErr] = useState(false)
    const [projectNameErr, setProjectNameErr] = useState(false)
    const [categoryNameError, setCategoryNameError] = useState(false)
    const [assignToErr, setAssignToErr] = useState(false)
    const [startDateErr, setStartDateErr] = useState(false)
    const [endDateErr, setEndDateErr] = useState(false)
    const [initialEstiErr, setInitialEstiErr] = useState(false)
  
    const handlerValidate = () => {
      let FormValid = true;
  
      if (addtask.taskName.length === 0) {
        FormValid = false;
        setTaskNameErr(true);
      }
      else if (taskpriority.length === 0) {
        FormValid = false;
        setTaskPriorityErr(true);
      }
      else if (addtask.taskdescription.length === 0) {
        FormValid = false;
        setTaskDescErr(true);
      }
      else if (departmentName.length === 0) {
        FormValid = false;
        setTaskDeptErr(true);
      }
      else if (projectname.length === 0) {
        FormValid = false;
        setProjectNameErr(true);
      }
      else if (categoryname.length === 0) {
        FormValid = false;
        setCategoryNameError(true);
      }
      else if (assignto.length === 0) {
        FormValid = false;
        setAssignToErr(true);
      }
      else if (proposedStartDate.length === 0) {
        FormValid = false;
        setStartDateErr(true);
      }
      else if (proposedEndDate.length === 0) {
        FormValid = false;
        setEndDateErr(true);
      }
      else if (initialHr.length === 0) {
        FormValid = false;
        setInitialEstiErr(true);
      }
  
      return FormValid;
    };
  
    const handleInput = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      setAddTask({ ...addtask, [name]: value })
    }
  
    // custom hook 
    const { allDepartmentOptions, allDepartmentOptionsById } = useGetDepartments()
    const { allProjectOptions, allProjectOptionsById } = useGetAllProjects()
    const { allCategoriesOptions } = useGetAllCategories()
  
    const getDptWiseTl = async() =>{
        const data = {      
        "departmentName" : departmentName.label
        }
        await axios.post(process.env.REACT_APP_BASE_URL + '/department/getDepartmentTL', data)
        .then((res) => {
            const filterOption = (res.data.data).length > 0 && (res.data.data).map((itm) => {
                return ({ value: itm.teamLeadId, label: itm.teamLeadName })
            })
            if (filterOption.length > 0) {
                setAllAssignToOptions(filterOption)
            }
        })
        .catch((err) => {
            console.log("Error", err)
        })
    }

    useEffect(()=>{
        getDptWiseTl()
    },[departmentName.label])
  
    const handleSubmit = (e) => {
      setTaskNameErr(false)
      setTaskPriorityErr(false)
      setTaskDescErr(false)
      setTaskDeptErr(false)
      setProjectNameErr(false)
      setCategoryNameError(false)
      setAssignToErr(false)
      setStartDateErr(false)
      setEndDateErr(false)
      setInitialEstiErr(false)
  
      // axios.post
      if (handlerValidate()) {
        if(parseFloat(initialHr) === 0){
          EstimateHr();
        }else{
        const payload = {
          "empId": assignto?.value,
          "taskName": addtask?.taskName,
          "taskDescription": addtask?.taskdescription,
          "taskPriority": taskpriority?.value,
          "department_id": (loginUserData?.department).some((itm) => itm.label === departmentName.label) ? departmentName.value : loginUserData?.department[0]?.value,
          "departmentName": (loginUserData?.department).some((itm) => itm.label === departmentName.label) ? departmentName.label : loginUserData?.department[0]?.label,
          "taskDepartmentId": departmentName.value,
          "taskDepartmentName": departmentName.label,
          "projectId": projectname.value,
          "projectName": projectname.label,
          "AssignTask": "",
          "assignBy_id": userData?.empId,
          "assignBy_Name": `${userData.firstName} ${userData.lastName}`,
          "resource_id": assignto.value,
          "resourceName": assignto.label,
          "categoryOfName": categoryname.label,
          "category_id": categoryname.value,
          "proposedStartDate": new Date(proposedStartDate).toISOString().split('T')[0],
          "proposedEndDate": new Date(proposedEndDate).toISOString().split('T')[0],
          "initialEstimateHr": initialHr,
          "estimateHr": "",
          "status": "pending",
          "logStatus": {
            "status": "pending",
            "resource_id": assignto.value,
            "resourceName": assignto.label
          }
        }
  
        axios.post(process.env.REACT_APP_BASE_URL + '/task/addTask', payload)
          .then((res) => {
            setAddTask({
              taskName: "",
              taskdescription: "",
              proposedstartdate: "",
              proposedenddate: "",
              initialEstimateHr: "",
              logStatus: {
                status: "",
                resource_id: "",
                resourceName: "",
              },
            });
            setTaskpriority("")
            setDepartmentName("")
            setProjectname("")
            setCategoryname("")
            setAssignto("")
            setProposedStartDate("")
            setProposedEndDate("")
            setInitialHr("")
            if(res.data.msg !== "Add Task Successfully"){
              AlreadyTaskAdd()
            } else{
                AddTaskSuccess(res.data.data);
                close()
            } 
            if ((loginUserData?.department).some((itm) => itm.label === departmentName.label)) {
              dispatch(setActiveKey({ activeKey: 1, activeTab: 'Home' }))
              setTimeout(()=>{
                navigate(`/${loginUserData?.resourceType}`)
              },1000)
            }else{
              setTimeout(()=>{
                navigate(`/${loginUserData?.resourceType}/assign-task`)
              },1000)
              
            }
          })
          .catch((err) => {
            console.log("Error", err)
          })
      }
    }
    }
    return (
      <>
       <ToastContainer />
      <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
        <Modal.Header closeButton className="mngmodelheader">
          <Modal.Title><h5>Create Task</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body size="lg">
          <Row className='mb-4'>
            <Col sm={12} md={6}>
              <Form.Group controlId="formBasicId">
                <Form.Label className="mt-3 fw-bold">
                  Task Title
                  <span className="requiredfield">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="Task Name"
                    className="input-field project-inputgroup"
                    name="taskName"
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        handleInput(e);
                      } else if (e.target.value.length === 0) {
                        handleInput(e);
                      }
                      addtask.taskName.length >= 0
                        ? setTaskNameErr(false)
                        : setTaskNameErr(true);
                    }}
                  />
                </InputGroup>
                {taskNameErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="">
                <Form.Label className="emp-form-label mt-3 fw-bold">
                  Task Priority
                  <span className="requiredfield">*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={taskpriority}
                  options={taskPriorityOptions}
                  onChange={(option) => {
                    setTaskpriority(option);
                    taskpriority.length < 0
                      ? setTaskPriorityErr(true)
                      : setTaskPriorityErr(false)
                  }}
                />
                {taskPriorityErrr ? (
                  <p className="errMsg" >
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
  
          <Row className='mb-4'>
            <Col sm={12} md={12}>
              <Form.Group className="">
                <Form.Label className="mt-3 fw-bold">
                  Task Description{" "}
                  <span className="requiredfield">*</span>
                </Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Task Description"
                    name='taskdescription'
                    onChange={(e) => {
                      if (
                        e.target.value.trim()
                      ) {
                        handleInput(e);
                      } else if (e.target.value.length === 0) {
                        handleInput(e);
                      }
                      addtask.taskdescription.length >= 0
                        ? setTaskDescErr(false)
                        : setTaskDescErr(true);
                    }}
                  />
                {taskDescErr ? (
                  <p className="errMsg" >
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
  
          <Row className='mb-4'>
            <Col sm={12} md={6}>
              <Form.Group className="">
                <Form.Label className="mt-3 fw-bold">
                  Department Name
                  <span className="requiredfield">*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={departmentName}
                  options={allDepartmentOptionsById}
                  onChange={(option) => {
                    setDepartmentName(option);
                    setAssignto(null)
                    setCategoryname(null)
                    departmentName.length < 0
                      ? setTaskDeptErr(true)
                      : setTaskDeptErr(false);
                  }}
                />
                {tastDeptErr ? (
                  <p className="error-padding" style={{ color: "red" }}>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group className="">
                <Form.Label className="mt-3 fw-bold">
                  Project Name
                  <span className="requiredfield">*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={projectname}
                  options={allProjectOptionsById}
                  onChange={(option) => {
                    setProjectname(option);
                    projectname.length < 0
                      ? setProjectNameErr(true)
                      : setProjectNameErr(false);
                  }}
                />
                {projectNameErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
  
          <Row className='mb-4'>
            <Col sm={12} md={4}>
              <Form.Group className="">
                <Form.Label className="emp-form-label mt-3 fw-bold">
                  Category Name
                  <span className="requiredfield">*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={categoryname}
                  options={allCategoriesOptions}
                  onChange={(option) => {
                    setCategoryname(option);
                    categoryname?.length < 0
                      ? setCategoryNameError(true)
                      : setCategoryNameError(false);
                  }}
                />
                {categoryNameError ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group className="">
                <Form.Label className="mt-3 fw-bold">
                  Assign To
                  <span className="requiredfield">*</span>
                </Form.Label>
                <Select
                  isMulti={false}
                  isSearchable={true}
                  value={assignto}
                  options={allAssignToOptions}
                  onChange={(option) => {
                    setAssignto(option);
                    assignto.length < 0
                      ? setAssignToErr(true)
                      : setAssignToErr(false);
                  }}
                />
                {assignToErr ? (
                  <p className="error-padding" style={{ color: "red" }}>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group controlId="formBasicId">
                <Form.Label className="mt-3 fw-bold">
                  Assign By
                </Form.Label>
                <Form.Control value={`${userData.firstName} ${userData.lastName}`} readOnly />
              </Form.Group>
            </Col>
          </Row>
  
          <Row className='mb-4'>
            <Col sm={12} md={4}>
              <Form.Group controlId="formBasiContact" className="w-100">
                <Form.Label className="mt-3 fw-bold">
                  Proposed Start date{" "}
                  <span className="requiredfield">*</span>
                </Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name="proposedStartDate"
                    value={proposedStartDate}
                    min={new Date().toISOString().split("T")[0]}
                    onKeyDown={(event) => event.preventDefault()}
                    onChange={(e) => {
                      setProposedStartDate(e.target.value);
                      proposedStartDate?.length < 0
                        ? setStartDateErr(true)
                        : setStartDateErr(false);
                    }}
                  />
                {startDateErr ? (
                  <p className="errMsg" style={{ color: "red" }}>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
                <Form.Label className="mt-3 fw-bold">
                  Proposed End date{" "}
                  <span className="requiredfield">*</span>
                </Form.Label>
                  <Form.Control
                    className=""
                    type="date"
                    name='proposedEndDate'
                    value={proposedEndDate}
                    min={proposedStartDate}
                    disabled={
                      proposedStartDate?.length === 0 ? true : false
                    }
                    onChange={(e) => {
                      setProposedEndDate(e.target.value);
                      proposedEndDate.length < 0
                        ? setEndDateErr(true)
                        : setEndDateErr(false);
                    }}
                  />
                {endDateErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group controlId="formBasiContact" style={{ width: "100%" }}>
                <Form.Label className="mt-3 fw-bold">
                  Initial Estimate(Hours){" "}
                  <span className="requiredfield">*</span>
                </Form.Label>
                  <Form.Control
                    type="text"
                    className=""
                    name="initialEstimateHr"
                    value={initialHr}
                    onChange={(e) => {
                        if(/^[0-9]*(\.[0-9]*)?$/.test(e.target.value) && e.target.value.length <= 3){
                          setInitialHr(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setInitialHr(e.target.value);
                        }
                      initialHr?.length < 0
                        ? setInitialEstiErr(true)
                        : setInitialEstiErr(false);
                    }}
                  />
                {initialEstiErr ? (
                  <p className="errMsg">
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
  
        </Modal.Body>
  
        <Modal.Footer className="text-center mt-4">
          <Button className="primaryBtn " onClick={() => { handleSubmit() }}>
            <Icon.PersonAdd className='me-2' /> Create Task
          </Button>
        </Modal.Footer>
  
      </Modal>
      </>
      
    )
}

import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Form, Nav, Spinner } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../Auth/Login/LoginSlice";
import { setActiveKey } from "../Common/ActiveKeySlice";
import Ftrack from '../../Assets/Ftrack.svg';
import { setProjects } from "../Auth/Login/ProjectSlice";
import { setAllStateEmpty } from "../Employee/EmpSlice";
import { getAllTasks, setActiveDashToggle, setActiveToggle } from "./AllTaskSlice";

const Sidebar = () => {

  const userState = useSelector(state => state.loginData.userData);
  const activeKeyRedux = useSelector(state => state.activeData?.activeNumberKey);
  const roleOfUser = ["employee", "contractual", "intern"];

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setProjects(null));
    dispatch(setAllStateEmpty([]))
    dispatch(setActiveKey(1));
    dispatch(setActiveToggle('pending'));
    dispatch(setActiveDashToggle('Self Dashboard'));
    dispatch(getAllTasks(null));
    navigate('/');
    localStorage.removeItem('persist:root')
    setLoader(false);
  }

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(setActiveKey({ activeKey: activeKey, subActiveKey: subActiveKey, activeTab: tabName }));
  };


  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column" >
        <div>
          <div className="my-4 text-center">
            <img src={Ftrack} alt="Ftrack" width={200} className="mb-2" />
            <hr></hr>
          </div>

          {/* for employee */}
          {
            roleOfUser.includes(userState?.resourceType) &&
            <Accordion flush activeKey={activeKeyRedux?.activeKey}>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate(`/${userState?.resourceType}`); }} className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("Dashboard"); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Reviews"); navigate("review"); }} className={activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.ChatLeftTextFill className='me-2' /> : <Icon.ChatLeftText className='me-2 icon' />}Reviews</Accordion.Header>
              </Accordion.Item>

            </Accordion>
          }

          {
            userState.resourceType === "manager" &&
            <>
              <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

                <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/manager"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                  <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
                </Accordion.Item>

                <Accordion.Item eventKey={4} className={activeKeyRedux.activeKey === 4 ? "activeMenu dropDown" : "dropDown"}>
                  <Accordion.Header onClick={() => { handleAccordionChange(4, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                    {activeKeyRedux?.activeKey === 4 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                  <Accordion.Body className="d-flex flex-column">
                    <Button onClick={() => { navigate("employee"); handleAccordionChange(4, 4.1, "View Employee") }} className={activeKeyRedux?.subActiveKey === 4.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                      {activeKeyRedux?.subActiveKey === 4.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                    </Button>
                    <Button onClick={() => { navigate("employee-task"); handleAccordionChange(4, 4.2, "Employee Task") }} className={activeKeyRedux?.subActiveKey === 4.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                      {activeKeyRedux?.subActiveKey === 4.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                    </Button>
                    <Button onClick={() => { navigate("no-tasks"); handleAccordionChange(4, 4.3, "No Task") }} className={activeKeyRedux?.subActiveKey === 4.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                      {activeKeyRedux?.subActiveKey === 4.3 ? <Icon.PersonFillSlash className="me-2" /> : <Icon.PersonSlash className="me-2" />} No Task
                    </Button>
                    <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(4, 4.4, "Resource Utilization") }} className={activeKeyRedux?.subActiveKey === 4.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                      {activeKeyRedux?.subActiveKey === 4.4 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                    </Button>
                    <Button onClick={() => { navigate("review"); handleAccordionChange(4, 4.5, "Employee Reviews") }} className={activeKeyRedux?.subActiveKey === 4.5 ? "accordionNavActive" : "accordionNav"}>
                      {activeKeyRedux?.subActiveKey === 4.5 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey={8} className={activeKeyRedux?.activeKey === 5 ? "activeMenu dropDown" : "dropDown"}>
                  <Accordion.Header onClick={() => { handleAccordionChange(5, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 5 ?
                    <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                  <Accordion.Body>
                    <Button onClick={() => { navigate("assign-task"); handleAccordionChange(5, 5.1, "Other Department Task") }} className={activeKeyRedux?.subActiveKey === 5.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                      {activeKeyRedux?.subActiveKey === 5.1 ? <Icon.PersonUp className="me-2" /> : <Icon.PersonUp className="me-2" />} Other Dept Task
                    </Button>
                    <Button onClick={() => { navigate("allocated-task"); handleAccordionChange(5, 5.2, "Allocated Task") }} className={activeKeyRedux?.subActiveKey === 5.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                      {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.PersonAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Allocated Task
                    </Button>
                    <Button onClick={() => { navigate("self-task"); handleAccordionChange(5, 5.3, "Self Task") }} className={activeKeyRedux?.subActiveKey === 5.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                      {activeKeyRedux?.subActiveKey === 5.3 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                    </Button>
                    <Button onClick={() => { navigate("self-review"); handleAccordionChange(5, 5.4, "Self Review") }} className={activeKeyRedux?.subActiveKey === 5.4 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                      {activeKeyRedux?.subActiveKey === 5.4 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                    </Button>
                  </Accordion.Body>

                </Accordion.Item>

              </Accordion>
            </>
          }

          {
            userState.resourceType === "teamlead" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>

              {/* <Form.Label>Main</Form.Label> */}

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/teamlead"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); }} className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} className={activeKeyRedux.activeKey === 4 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(4, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 4 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(4, 4.1, "View Employee") }} className={activeKeyRedux?.subActiveKey === 4.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 4.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(4, 4.2, "Employee Task") }} className={activeKeyRedux?.subActiveKey === 4.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 4.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("no-tasks"); handleAccordionChange(4, 4.3, "No Task") }} className={activeKeyRedux?.subActiveKey === 4.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 4.3 ? <Icon.PersonFillSlash className="me-2" /> : <Icon.PersonSlash className="me-2" />} No Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(4, 4.4, "Resource Utilization") }} className={activeKeyRedux?.subActiveKey === 4.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 4.4 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(4, 4.5, "Employee Reviews") }} className={activeKeyRedux?.subActiveKey === 4.5 ? "accordionNavActive" : "accordionNav"}>
                    {activeKeyRedux?.subActiveKey === 4.5 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey={5} className={activeKeyRedux?.activeKey === 5 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(5, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 5 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("assign-task"); handleAccordionChange(5, 5.1, "Other Department Task") }} className={activeKeyRedux?.subActiveKey === 5.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.1 ? <Icon.PersonUp className="me-2" /> : <Icon.PersonUp className="me-2" />} Other Dept Task
                  </Button>
                  <Button onClick={() => { navigate("allocated-task"); handleAccordionChange(5, 5.2, "Allocated Task") }} className={activeKeyRedux?.subActiveKey === 5.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Allocated Task
                  </Button>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(5, 5.3, "Self Task") }} className={activeKeyRedux?.subActiveKey === 5.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.3 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(5, 5.4, "Self Review") }} className={activeKeyRedux?.subActiveKey === 5.4 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 5.4 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(6, 6, "To Do List"); navigate("todolist"); }} className={activeKeyRedux?.activeKey === 6 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 6 ? <Icon.Stickies className='me-2' /> : <Icon.Stickies className='me-2 icon' />}To Do List</Accordion.Header>
              </Accordion.Item>
            </Accordion>
          }

          {
            userState.resourceType === "humanResource" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/humanResource"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); }} className={activeKeyRedux.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} className={activeKeyRedux.activeKey === 3 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(3, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 3 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(3, 3.1, "View Employee") }} className={activeKeyRedux?.subActiveKey === 3.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 3.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(3, 3.2, "Employee Task") }} className={activeKeyRedux?.subActiveKey === 3.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 3.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(3, 3.3, "Resource Utilization") }} className={activeKeyRedux?.subActiveKey === 3.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 3.3 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(3, 3.4, "Employee Reviews") }} className={activeKeyRedux?.subActiveKey === 3.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 3.4 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={4} className={activeKeyRedux?.activeKey === 4 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(4, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 4 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("assign-task"); handleAccordionChange(4, 4.1, "Other Department Task") }} className={activeKeyRedux?.subActiveKey === 4.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.1 ? <Icon.PersonUp className="me-2" /> : <Icon.PersonUp className="me-2" />} Other Dept Task
                  </Button>
                  <Button onClick={() => { navigate("allocated-task"); handleAccordionChange(4, 4.2, "Allocated Task") }} className={activeKeyRedux?.subActiveKey === 4.2 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Allocated Task
                  </Button>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(4, 4.3, "Self Task") }} className={activeKeyRedux?.subActiveKey === 4.3 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.3 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(4, 4.4, "Self Review") }} className={activeKeyRedux?.subActiveKey === 4.4 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 4.4 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey={5} onClick={() => { handleAccordionChange(5, 5, "Report"); navigate("report"); }} className={activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 5 ? <Icon.FileEarmarkArrowDown className='me-2' /> : <Icon.FileEarmarkArrowDown className='me-2 icon' />}Report</Accordion.Header>
              </Accordion.Item> */}

            </Accordion>
          }


          {
            userState.resourceType === "admin" &&
            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
              <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Home"); navigate("/admin"); }} className={activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 1 ? <Icon.HouseFill className='me-2' /> : <Icon.House className='me-2 icon' />}Home</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Dashboard"); navigate("dashboard"); }} className={activeKeyRedux.activeKey === 2 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 2 ? <Icon.BarChartFill className='me-2' /> : <Icon.BarChart className='me-2 icon' />}Dashboard</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Project"); navigate("project"); }} className={activeKeyRedux.activeKey === 3 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 3 ? <Icon.Sliders2 className='me-2' /> : <Icon.Sliders2 className='me-2 icon' />}Manage Project</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Client"); navigate("client"); }} className={activeKeyRedux.activeKey === 4 ? "activeMenu" : ""}>
                <Accordion.Header >{activeKeyRedux?.activeKey === 4 ? <Icon.PersonLinesFill className='me-2' /> : <Icon.PersonLinesFill className='me-2 icon' />}Manage Client</Accordion.Header>
              </Accordion.Item>

              <Accordion.Item eventKey={5} className={activeKeyRedux.activeKey === 5 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(5, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
                  {activeKeyRedux?.activeKey === 5 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Manage Employee</Accordion.Header>

                <Accordion.Body className="d-flex flex-column">
                  <Button onClick={() => { navigate("employee"); handleAccordionChange(5, 5.1, "View Employee") }} className={activeKeyRedux?.subActiveKey === 5.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.1 ? <Icon.EyeFill className="me-2" /> : <Icon.Eye className="me-2" />} View Employee
                  </Button>
                  <Button onClick={() => { navigate("employee-task"); handleAccordionChange(5, 5.2, "Employee Task") }} className={activeKeyRedux?.subActiveKey === 5.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.2 ? <Icon.PersonFillAdd className="me-2" /> : <Icon.PersonAdd className="me-2" />} Employee Task
                  </Button>
                  <Button onClick={() => { navigate("resourceutilize"); handleAccordionChange(5, 5.3, "Resource Utilization") }} className={activeKeyRedux?.subActiveKey === 5.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.3 ? <Icon.ColumnsGap className="me-2" /> : <Icon.ColumnsGap className="me-2" />} Resource Utilization
                  </Button>
                  <Button onClick={() => { navigate("review"); handleAccordionChange(5, 5.4, "Employee Reviews") }} className={activeKeyRedux?.subActiveKey === 5.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
                    {activeKeyRedux?.subActiveKey === 5.4 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />}Employee Reviews
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey={6} className={activeKeyRedux?.activeKey === 6 ? "activeMenu dropDown" : "dropDown"}>
                <Accordion.Header onClick={() => { handleAccordionChange(6, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }} >{activeKeyRedux?.activeKey === 6 ?
                  <Icon.Incognito className='me-2' /> : <Icon.Incognito className='me-2 icon' />}Manage Self Tasks</Accordion.Header>

                <Accordion.Body>
                  <Button onClick={() => { navigate("self-task"); handleAccordionChange(6, 6.1, "Self Task") }} className={activeKeyRedux?.subActiveKey === 6.1 ? "accordionNavActive mb-3 w-100" : "accordionNav mb-3 w-100"}>
                    {activeKeyRedux?.subActiveKey === 6.1 ? <Icon.PersonVcardFill className="me-2" /> : <Icon.PersonVcard className="me-2" />} Self Task
                  </Button>
                  <Button onClick={() => { navigate("self-review"); handleAccordionChange(6, 6.2, "Self Review") }} className={activeKeyRedux?.subActiveKey === 6.2 ? "accordionNavActive w-100" : "accordionNav w-100"}>
                    {activeKeyRedux?.subActiveKey === 6.2 ? <Icon.ChatLeftDotsFill className="me-2" /> : <Icon.ChatLeftDots className="me-2" />} Self Reviews
                  </Button>
                </Accordion.Body>

              </Accordion.Item>

            </Accordion>
          }
        </div>

        <div className="pb-4 ps-2">
          <Button className='LogoutBtn w-100' onClick={handleLogout}>{loader ? <Spinner animation="border" variant="light" className='spinner' /> : <><Icon.BoxArrowLeft size={18} className='me-2' />Logout</>}</Button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;





import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import Menubar from '../Features/Common/Menubar';
import Sidebar from '../Features/Common/Sidebar';

const CommonLayout = () => {
  return (
    <div>
    <Container fluid>
    <Row>
    <div className='sidenav-lg-parent'>
    <Col className='ps-0 d-none d-lg-block'>
      <Sidebar/>
    </Col>
      <Col>
        <div className='outletDiv'>
        <Menubar/>
        <Outlet/>
      </div>
      </Col>
      </div>
    </Row>
  </Container>
  </div>
  )
}

export default CommonLayout;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    homeTask: [],
    empViewDetails: [],
    empViewTask: [],
    mngAllEmpTaskReview: [],
    mngSelfReview: [],
    mngManageEmp: [],
    loading: 'idle',
}

// for getallhometask
export const getHomeAllTasks = createAsyncThunk("getHomeAllTaskPromise", async (data, { rejectWithvalue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/task/getTaskByDepartment', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithvalue(error)
    }
})

// for getallempdetails
export const getEmpViewDetails = createAsyncThunk("getEmpViewDetailsPromise", async (data, { rejectWithvalue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/emp/getallemp1', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithvalue(error)
    }
})

// for employee all task in manage employee
export const getEmpViewTask = createAsyncThunk("getEmpViewTaskPromise", async (data, { rejectWithvalue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/task/employeeTask', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithvalue(error)
    }
})




// ------------  18-07-2023 -------------  //

export const getAllReview = createAsyncThunk("getAllReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/reviewTask', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

export const getSelfReview = createAsyncThunk("getSelfReviewPromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_NEW_BASE_URL + '/selfReview', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error);
    }
}
)

// ------------  20-07-2023 -------------  //
export const getEmployee = createAsyncThunk("getEmployeePromise", async (data, { rejectWithValue }) => {
    const response = await fetch(process.env.REACT_APP_BASE_URL + data.endPoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data.payload)
    })
    try {
        const result = await response.json()
        return result
    } catch (error) {
        return rejectWithValue(error)
    }
}
)

const MngSlice = createSlice({
    name: 'manager',
    initialState,
    reducers: {
        setHomeTask(state, action) {
            state.homeTask = action.payload
        }
    },
    extraReducers: builder => {
        builder
            //for getallhometask
            .addCase(getHomeAllTasks.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getHomeAllTasks.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getHomeAllTasks.fulfilled, (s, a) => {
                s.homeTask = a.payload.data;
                s.loading = 'success';
            })

            // for getallempdetails
            .addCase(getEmpViewDetails.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getEmpViewDetails.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getEmpViewDetails.fulfilled, (s, a) => {
                s.empViewDetails = a.payload;
                s.loading = 'success';
            })

            // for get employee task in manage employee
            .addCase(getEmpViewTask.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getEmpViewTask.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getEmpViewTask.fulfilled, (s, a) => {
                s.empViewTask = a.payload;
                s.loading = 'success';
            })


            // ----new chnages 18-07-2023

            // get all employee reviews
            .addCase(getAllReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getAllReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getAllReview.fulfilled, (s, a) => {
                s.mngAllEmpTaskReview = a.payload;
                s.loading = 'success';
            })


            // self reviews
            .addCase(getSelfReview.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getSelfReview.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getSelfReview.fulfilled, (s, a) => {
                s.mngSelfReview = a.payload;
                s.loading = 'success';
            })

            // self reviews
            .addCase(getEmployee.pending, (s, a) => {
                s.loading = "pending";
            })
            .addCase(getEmployee.rejected, (s, a) => {
                s.loading = "idle";
            })
            .addCase(getEmployee.fulfilled, (s, a) => {
                s.mngManageEmp = a.payload;
                s.loading = 'success';
            });



    },
});

export const { setHomeTask } = MngSlice.actions;
export default MngSlice.reducer;

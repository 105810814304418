import axios from 'axios'
import React, { useEffect, useState } from 'react'

const useGetDeptWiseResource = (urlEndpoint, payload, departmentName, selftDept) => {
    const [allAssignToOptions, setAllAssignToOptions] = useState([])

    useEffect(() => {
        try {
            
            if (selftDept?.includes(departmentName?.label) || !departmentName) {
                axios.post(process.env.REACT_APP_BASE_URL + urlEndpoint, payload)
                    .then((res) => {
                        const filterOption = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                            return ({ value: itm.empId, label: `${itm.firstName} ${itm.lastName}` })
                        })

                        if (filterOption?.length > 0) {
                            setAllAssignToOptions(filterOption)
                        }
                    })
                    .catch((err) => {
                        console.log("Error", err)
                    })
            } 
            else {axios.post(process.env.REACT_APP_BASE_URL + urlEndpoint, payload)
                    .then((res) => {
                        const filterOption = (res?.data?.data)?.length > 0 && (res?.data?.data)?.map((itm) => {
                            return ({ value: itm.empId, label: `${itm.firstName} ${itm.lastName}` })
                        })

                        if (filterOption?.length > 0) {
                            setAllAssignToOptions(filterOption)
                        }
                    })
                    .catch((err) => {
                        console.log("Error", err)
                    })
            }

        } catch (error) {
            console.log("Error", error)
        }
    }, [departmentName?.value])

    return { allAssignToOptions }
}

export default useGetDeptWiseResource
import axios from 'axios'
import moment from 'moment/moment';
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Badge from 'react-bootstrap/Badge';
import Select from "react-select";
import useGetAllCategories from '../../Common/ReuseApi/useGetAllCategories';
import useGetDepartments from '../../Common/ReuseApi/useGetDepartments';

export const EditClientModel = (props) => {
    const {show, close, item} = props

    const toastSuccess = () => {
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:1000,
        });
      };

    const [updateclient, setUpdateClient] = useState({
        clientName: "",
        contactNo: "",
        company: "",
        address: "",
        details: "",
        email: "",
    })
    const [status, setStatus] = useState("")

    // for error
  const [clientnameerror, setClientNameError] = useState(false);
  const [clientcontacterror, setClientConatctError] = useState(false);
  const [clientcompanyerror, setClientCompanyError] = useState(false);
  const [clientaddresserror, setClientAddressError] = useState(false);
  const [clientdetailserror, setClientDetailsError] = useState(false);
  const [clientemailerror, setClientEmailError] = useState(false);
  const [clientstatuserror, setClientStatusError] = useState(false);

    useEffect(()=>{
        setUpdateClient({
            _id: item?._id,
            clientName: item?.clientName,
            contactNo: item?.contactNo,
            company: item?.company,
            address: item?.address,
            details: item.details,
            email: item?.email,
            status: item?.status,
        });
        setStatus({label: item?.status, value: item?.status})
    },[props])

     const StatusOptions = [{ value: 'inactive', label: 'In Active' }, { value: 'active', label: 'Active' }];


    let name, value;
    const handleClient = (e) =>{
        name = e.target.name;
        value = e.target.value;
        setUpdateClient({ ...updateclient, [name]: value });
    }

    const handlerUpdateVaidation = () => {

    let FormValid = true;
    if (updateclient?.clientName.length === 0) {
      FormValid = false;
      setClientNameError(true);
    }
    else if (updateclient?.company.length === 0) {
      FormValid = false;
      setClientCompanyError(true);
    }
    else if (updateclient?.contactNo.length === 0) {
      FormValid = false;
      setClientConatctError(true);
    } else if (updateclient?.contactNo.length < 10) {
      FormValid = false;
      setClientConatctError(true);
    }

     else if (updateclient?.email.length === 0) {
      FormValid = false;
      setClientEmailError(true);
    }

    else if (updateclient?.details.length === 0) {
      FormValid = false;
      setClientEmailError(true);
    }
       
    else if (updateclient?.status.length === 0) {
      FormValid = false;
      setClientDetailsError(true);
    }
    
    else if (updateclient?.address.length === 0) {
      FormValid = false;
      setClientAddressError(true);
    }
    return FormValid;
  };
    const handleEditSubmit = async() =>{
        setClientNameError(false);
        setClientAddressError(false);
        setClientConatctError(false);
        setClientEmailError(false);
        setClientDetailsError(false);
        setClientCompanyError(false);
        setClientStatusError(false)

        if(handlerUpdateVaidation()){
            const payload = {
                _id: updateclient._id,
                clientName: updateclient.clientName,
                contactNo: updateclient.contactNo,
                company: updateclient.company,
                address: updateclient.address,
                details: updateclient.details,
                email: updateclient.email,
                status:status.value
            }
            await axios.post(process.env.REACT_APP_BASE_URL + "/client/updateClinet", payload)
            .then((res) => {
                toastSuccess()
                setTimeout(()=>{
                  props.close()
                }, 1000)
            })
            .catch((err) => {
              console.log("Error from update api", err);
            })
        }
    }

  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header
      closeButton
      style={{
        color: "black",
        width: "100%",
        height: "50px",
        backgroundColor: "#F9D65F",
      }}
    >
      <Modal.Title style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
        Edit Client
      </Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label>
                Client Name
                <span className="requiredfield">*</span>
              </Form.Label>
               <Form.Control
                    name="clientName"
                    value={updateclient.clientName}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[0-9+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        handleClient(e);
                      } else if (e.target.value.length === 0) {
                        handleClient(e);
                      }
                      updateclient?.clientName?.length >= 0
                        ? setClientNameError(false)
                        : setClientNameError(true);
                    }}
                  />
              {clientnameerror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
          </Col>

           <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label >
                Company Name
                <span className="requiredfield">*</span>
              </Form.Label>
               <Form.Control
                    name="company"
                    value={updateclient.company}
                    onChange={(e) => {
                      if (
                        !e.target.value.match(
                          /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                        ) &&
                        e.target.value.trim()
                      ) {
                        handleClient(e);
                      } else if (e.target.value.length === 0) {
                        handleClient(e);
                      }
                      updateclient?.company?.length >= 0
                        ? setClientCompanyError(false)
                        : setClientCompanyError(true);
                    }}
                  />
              {clientcompanyerror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="">
              <Form.Label >
                Conatct No
                <span className="requiredfield">*</span>
              </Form.Label>
              <Form.Control
                  name="contactNo"
                  value={updateclient.contactNo}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[A-Za-z+@#$&%!~=^_:(){}\[\]*.|/\s/g/\-/?<>,;`'""/\\]/
                      ) &&
                      e.target.value.length <= 10
                    ) {
                      handleClient(e)
                    } else if (e.target.value.length === 0) {
                       handleClient(e)
                    }
                    updateclient?.contactNo?.length <= 0
                      ? setClientConatctError(true)
                      : setClientConatctError(false);
                  }}
                >
                </Form.Control>
                {clientcontacterror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group>
              <Form.Label >
                Email
                <span className="requiredfield">*</span>
              </Form.Label>
             <Form.Control
                  name="email"
                  value={updateclient.email}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[A-Z+#$&%!~=^_:(){}\[\]*|/\s/\-/?<>,;`'""/\\]/
                      )
                    ) {
                       handleClient(e);
                    } else if (e.target.value.length === 0) {
                      handleClient(e)
                    }
                    updateclient?.email?.length >= 0
                      ? setClientEmailError(false)
                      : setClientEmailError(true);
                  }}
                >
                </Form.Control>
                {clientemailerror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
            </Form.Group>
          </Col>
        </Row>
        
        <Row className='mb-4'>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label>
                Client Details
                <span className="requiredfield">*</span>
              </Form.Label>
              <Form.Control
                  name="details"
                  value={updateclient.details}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                       handleClient(e)
                    } else if (e.target.value.length === 0) {
                       handleClient(e)
                    }
                    updateclient?.details?.length >= 0
                      ? setClientDetailsError(false)
                      : setClientDetailsError(true);
                  }}
                >
                </Form.Control>
                {clientdetailserror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-1" />
                    </>
                    Field is Required
                  </p>
                ) : null}
            </Form.Group>
        </Col>
        
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label>
                 Status
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='status'
                value={status}
                options={StatusOptions}
                onChange={(option) => {
                  setStatus(option);
                  status.length < 0
                    ? setClientStatusError(true)
                    : setClientStatusError(false);
                }}
              />
              {clientstatuserror ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
        </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={12}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Address
                <span className="requiredfield">*</span>
              </Form.Label>
             <Form.Control
                  as="textarea"
                  value={updateclient.address}
                  name="address"
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      )
                    ) {
                      handleClient(e)
                    } else if (e.target.value.length === 0) {
                      handleClient(e)
                    }
                    updateclient?.address?.length >= 0
                      ? setClientAddressError(false)
                      : setClientAddressError(true);
                  }}
                />
                {clientaddresserror ? (
                  <p className='errMsg'>
                    <>
                      <Icon.ExclamationTriangle className="me-2" />
                    </>
                    Required Field
                  </p>
                ) : null}
            </Form.Group>
          </Col>
        </Row>
    </Modal.Body>

      <Modal.Footer className="text-center mt-4">
        <Button className="primaryBtn " 
        onClick={() => { handleEditSubmit() }}
        >
          <Icon.PersonAdd className='me-2' /> Edit Client
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}

import React, {useState } from 'react'
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import Select from "react-select";
import useGetTlAndEmp from '../../Common/ReuseApi/UseGetTLAndEmployee';
import axios from 'axios';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssignToTlModel = ({ show, close, userData }) => {

const { alltloption, allempoption } = useGetTlAndEmp();

//   usestate for tl and employee
const[tllist,setTLList] = useState("");
const[emplist,setEmpList] = useState([]);
const handleEmployeeChange = (selectedOptions) => {
  if (Array.isArray(selectedOptions)) {
    const selectedEmployees = selectedOptions.map((option) => option.value);
    setEmpList(selectedEmployees);
  } else {
    setEmpList([selectedOptions.value]);
  }
};
  const handleAssignUpdate = (e) => {
      const payload = {
         "leadId": tllist?.value,
         "empIds": emplist,
      }

      axios.post(process.env.REACT_APP_NEW_BASE_URL + '/assignEmployeeToLead', payload)
        .then((res) => {
          if(res.data.message === "Selected employees already exists." ){
             toast.error('Selected employees already exists.');
          }
          else{
            toast.success("Employees assigned successfully.");
          }
          // else if(res.data.message === `${emplist} employees assigned successfully.`){
          //    toast.success(`${emplist} employees assigned successfully.`);
          // }
          setTLList("")
          setEmpList([])
            setTimeout(() => {
            close()
            }, 1000)
        })
        .catch((err) => {
          console.log("Error", err)
        })
  }

  return (
    <>
     <ToastContainer />
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
      <Modal.Header closeButton className="mngmodelheader">
        <Modal.Title><h5>Assign Employee</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body size="lg">
        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                TeamLead
                {/* <span className="requiredfield">*</span> */}
              </Form.Label>
              <Select
              placeholder = "select TeamLead Name"
                isMulti={false}
                isSearchable={true}
                value={tllist}
                options={alltloption}
                onChange={(option) => {
                  setTLList(option);
                }}
              />
             
            </Form.Group>
          </Col>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Employee
                {/* <span className="requiredfield">*</span> */}
              </Form.Label>
              <Select
               placeholder = "Select Employee Name"
                isMulti={true}
                isSearchable={true}
                value={allempoption.filter((option) => emplist.includes(option.value))}
                options={allempoption}
                onChange={handleEmployeeChange}
                />
            </Form.Group>
          </Col>
        </Row>

      </Modal.Body>

      <Modal.Footer className="text-center mt-4">
        <Button className="primaryBtn " 
        onClick={() => { handleAssignUpdate() }}
        >
          <Icon.PersonAdd className='me-2' /> Assign
        </Button>
      </Modal.Footer>

    </Modal>
    </>
    
  )
}

export default AssignToTlModel
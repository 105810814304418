import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Select from "react-select";
import { getSelfReview } from '../../TeamLead/TLSlice';
import { getFilterOptionData ,getSelfReviewFilterOptionData} from '../../Employee/EmpSlice';
import { setFilterTasksData } from '../AllTaskSlice';
import axios from 'axios';

export const SelfReviewModel = ({ show, close,handleFilterTrue, handlerFilterFalse, showNoDataFound, currentPage, docsPerPage }) => {
    const dispatch = useDispatch()

    const loginUserData = useSelector((state) => state.loginData.userData)
    const allDropdownOption = useSelector(state => state?.employee?.getSelfReviewFilterOption)


    // state 
    const [projectName, setProjectName] = useState("")
    const [departmentName, setDepartmentName] = useState("")
    const [resourceName, setResourceName] = useState("")
    const [idcMin, setIDCMin] = useState("")
    const [idcMax, setIDCMax] = useState("")
    const [qwdMin, setQWDMin] = useState("")
    const [qwdMax, setQWDMax] = useState("")
    const [slippage, setSlippage] = useState("")
    const [efficiency, setEfficiency] = useState("")
    const [reviewDate, setReviewDate] = useState("")

// for handler submit
  const handleFilterSubmit = () => {
    if (
      projectName === "" &&
      departmentName === "" &&
      resourceName === "" &&
      idcMin === "" &&
      idcMax === "" &&
      qwdMin === "" &&
      qwdMax === "" &&
      slippage === "" &&
      efficiency === "" &&
      reviewDate === ""
    ) {
      dispatch(setFilterTasksData([]));
      handlerFilterFalse();
    } else {
      getAllReviewData();
    }
    close();
  };

    //for clear button
    const clearFilter = () => {
        setProjectName("")
        setDepartmentName("")
        setIDCMin("")
        setIDCMax("")
        setQWDMin("")
        setQWDMax("")
        setSlippage("")
        setEfficiency("")
        setReviewDate("")
    }


    const getAllReviewData = async() => {
        const payload = {
            "empId": loginUserData?.empId,
            "assignBy_Name": "",
            "departments": departmentName.value ? departmentName.value : "",
            "taskName": "",
            "projectName": projectName.value ?? "",
            "resource_id": "",
            "idcMin": idcMin === "" ? "0" : idcMin.value,
            "idcMax": idcMax === "" ? "10" : idcMax.value,
            "qwdMin": qwdMin === "" ? "0" : qwdMin.value,
            "qwdMax": qwdMax === "" ? "10" : qwdMax.value,
            "slippage": "",
            "efficiency": "",
            "reviewDate": reviewDate ?? "",
            "resourceType": "",
            "documentsPerPage": docsPerPage ? docsPerPage : 10,
            "page": currentPage ? currentPage : 1
        }
        await axios.post(process.env.REACT_APP_NEW_BASE_URL + '/selfReview', payload)
        .then((response) => {
            dispatch(setFilterTasksData(response?.data))
            handleFilterTrue()

        })
        .catch((error) => {
            console.log("Error", error)
        })
    }

    useEffect(() => {
    if (showNoDataFound) {
      getAllReviewData()
    }
  }, [currentPage, docsPerPage])
    

    useEffect(() => {
        const payload = {
            "empId": loginUserData?.empId,
        }
        dispatch(getSelfReviewFilterOptionData(payload))
    }, [])

    const IdcAndQwdOption = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" },
    ]

    return (
        <Modal
            show={show}
            onHide={close}
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title style={{ textAlign: 'center' }}><h5>Filter Reviews</h5></Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <Form.Group>
                            <Form.Label> Project </Form.Label>
                            <Select
                                isMulti={false}
                                options={
                                    allDropdownOption !== null &&
                                    allDropdownOption !== undefined &&
                                    allDropdownOption?.projects
                                }
                                value={projectName}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select Project name"
                                onChange={(option) => { setProjectName(option) }}
                                isSearchable={true}
                            />
                        </Form.Group>
                    </div>

                    <div className="col-sm-12 col-md-6">
                        <Form.Group>
                            <Form.Label className="emp-form-label mt-3 fw-bold">
                                Department Name
                            </Form.Label>
                            <Select
                                isMulti={false}
                                options={
                                    allDropdownOption !== null &&
                                    allDropdownOption !== undefined &&
                                    allDropdownOption?.departments
                                }
                                value={departmentName}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Select Department Name"
                                onChange={(option) => { setDepartmentName(option) }}
                                isSearchable={true}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-sm-12 col-md-3 mt-3">
                        <Form.Group>
                            <Form.Label> Min IDC </Form.Label>
                            <Select
                                isMulti={false}
                                options={IdcAndQwdOption}
                                value={idcMin}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Min IDC"
                                onChange={(option) => { setIDCMin(option) }}
                                isSearchable={true} />
                        </Form.Group>
                    </div>

                    <div className="col-sm-12 col-md-3 mt-3">
                        <Form.Group>
                            <Form.Label> Max IDC </Form.Label>
                            <Select
                                isMulti={false}
                                options={IdcAndQwdOption}
                                value={idcMax}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Max IDC"
                                onChange={(option) => { setIDCMax(option) }}
                                isSearchable={true} />
                        </Form.Group>
                    </div>

                    <div className="col-sm-12 col-md-3 mt-3">
                        <Form.Group>
                            <Form.Label> Min QWD </Form.Label>
                            <Select
                                isMulti={false}
                                options={IdcAndQwdOption}
                                value={qwdMin}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Min QWD"
                                onChange={(option) => { setQWDMin(option) }}
                                isSearchable={true} />
                        </Form.Group>
                    </div>

                    <div className="col-sm-12 col-md-3 mt-3">
                        <Form.Group>
                            <Form.Label> Max QWD </Form.Label>
                            <Select
                                isMulti={false}
                                options={IdcAndQwdOption}
                                value={qwdMax}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder="Max QWD"
                                onChange={(option) => { setQWDMax(option) }}
                                isSearchable={true} />
                        </Form.Group>
                    </div>

                    <div className="col-sm-12 col-md-6 mt-3">
                        <Form.Group>
                            <Form.Label className="emp-form-label fw-bold">
                                Review Date
                            </Form.Label>
                            <InputGroup>
                                <Form.Control type="date" onKeyDown={(event) => event.preventDefault()}
                                    name="reviewDate" value={reviewDate}
                                    onChange={(e) => setReviewDate(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </div>

                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
                <Button className="primaryBtn" onClick={() => { handleFilterSubmit() }}>Submit </Button>
            </Modal.Footer>
        </Modal>
    )
}

import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { getEmployeeData, getFilterManageEmp } from '../HrSlice';
import { getEmployee } from '../../Manager/MngSlice';

const FilterEmpDetailsModel = ({ show, close }) => {

  const loginUserData = useSelector((state) => state.loginData.userData)
  const manageEmpFilterData = useSelector(state => state?.employeeDetails?.filterManageEmp)
  const roleOfUser = ["humanResource","admin"];

  const dispatch = useDispatch()

  const [empId, setEmpId] = useState(null)
  const [empname, setEmpName] = useState(null)
  const [departmentName, setDepartmentName] = useState(null);
  const [resourceType, setResourceType] = useState(null)
  const [approval, setApproval] = useState(null)


  const getFilterData = () => {
    if (loginUserData?.resourceType === "humanResource" || loginUserData?.resourceType === "admin") {
      const payload = {
        empId: empId ? empId?.value : "",
        name:  empname ? empname?.value : "",
        label: departmentName ? departmentName?.value : "",
        resourceType: resourceType ? resourceType?.value : "",
        experience: "",
        isApproved: approval ? approval?.value : "",
        documentsPerPage: 10,
        page: 1
      }

      const hrEndpoint = "/emp/getallemp1"
      sessionStorage.setItem('filterPayload',JSON.stringify(payload))
      dispatch(getEmployeeData({ endpoint: hrEndpoint, payload }))
      close()
    } else {
      const endPoint = "/emp/getParticularTlEmployee1"
      const payload = {
        empIdTL: loginUserData?.empId,
        empId: empId ? empId.value : "",
        name: empname ? empname.value : "",
        label:departmentName ?  departmentName.value : "",
        designation: "",
        workEmail: "",
        isApproved: approval ? approval.value : "",
        resourceType: resourceType ? resourceType.value : "",
        documentsPerPage: 10,
        page: 1
      }

      sessionStorage.setItem('filterPayload',JSON.stringify(payload))
      dispatch(getEmployee({ endPoint, payload }))
      close()
    }

  }

  useEffect(() => {
    const payload = {
      "empIdTL": loginUserData?.empId
    }
    dispatch(getFilterManageEmp(payload))
  }, [show])

  const clearFilter = () => {
    setEmpId("")
    setEmpName("")
    setDepartmentName("")
    setResourceType("")
    setApproval("")
  }

  // for Approval status
  const ApprovalStatus = [
    { value: true, label: 'Approved' },
    { value: false, label: 'Rejected' },
    { value: null, label: 'Not Update' }
  ];

  const handleApproval = (selectedOption) => {
    setApproval(selectedOption);
  };

  return (
    <Modal
      show={show}
      onHide={close}
      style={{ color: "black" }}
      size="lg" centered backdrop="static" keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center' }}><h5>Filter</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label>
                Employee ID
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  manageEmpFilterData !== null &&
                  manageEmpFilterData !== undefined &&
                  manageEmpFilterData?.data?.empIds
                }
                value={empId}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Employee ID"
                onChange={(option) => { setEmpId(option); }}
                isSearchable={true}
              />
            </Form.Group>

          </Col>

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label>
                Employee Name
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  manageEmpFilterData !== null &&
                  manageEmpFilterData !== undefined &&
                  manageEmpFilterData?.data?.empNames
                }
                value={empname}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Employee Name"
                onChange={(option) => { setEmpName(option) }}
                isSearchable={true}
              />
            </Form.Group>

          </Col>

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label>
                Department
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  manageEmpFilterData !== null &&
                  manageEmpFilterData !== undefined &&
                  manageEmpFilterData?.data?.departments
                }
                value={departmentName}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Department Name"
                onChange={(option) => { setDepartmentName(option); }}
                isSearchable={true}
              />
            </Form.Group>
          </Col>

          <Col md={6} className='mb-3'>
            <Form.Group>
              <Form.Label>
                Resource Type
              </Form.Label>
              <Select
                isMulti={false}
                options={
                  manageEmpFilterData !== null &&
                  manageEmpFilterData !== undefined &&
                  manageEmpFilterData?.data?.resourceTypes
                }
                value={resourceType}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Resource Type"
                onChange={(option) => { setResourceType(option) }}
                isSearchable={true}
              />
            </Form.Group>
          </Col>
          {
            roleOfUser.includes(loginUserData?.resourceType) &&
            <Col md={6} className='mb-4'>
              <Form.Group>
                <Form.Label>
                  Approval Status
                </Form.Label>
                <Select
                  isMulti={false}
                  options={ApprovalStatus}
                  value={approval}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select Approval Status"
                  onChange={handleApproval}
                  isSearchable={true}
                />
              </Form.Group>
            </Col>
          }

          <Modal.Footer>
            <Button className="secondaryBtn" type='clear' onClick={clearFilter}>Reset</Button>
            <Button className="primaryBtn" onClick={() => { getFilterData() }}>Submit </Button>
          </Modal.Footer>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default FilterEmpDetailsModel

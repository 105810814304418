import axios from 'axios'
import moment from 'moment/moment';
import { InputGroup, Modal, Button, Form, Col, Row} from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import Badge from 'react-bootstrap/Badge';
import Select from "react-select";
import useGetAllCategories from '../../Common/ReuseApi/useGetAllCategories';
import useGetDepartments from '../../Common/ReuseApi/useGetDepartments';

export const EditProjectModel = (props) => {
    const {show, close, taskId, item, userData} = props

    const toastSuccess = () => {
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose:1000,
        });
      };

    const [updateProjects, setUpdateProjects] = useState({
        projName: "",
        clientName: "",
        projectId: "",
        category_Id: "",
        departmentId: "",
    })

    const [projectType, setProjectType] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [departmentName, setDepartmentName] = useState("")
    const [status, setStatus] = useState("")

    const [projectNameError, setProjNameError] = useState(false);
    const [projectClientError, setProjClientError] = useState(false);
    const [projTypeError, setProjTypeError] = useState(false)
    const [projCategoryError, setProjCategoryError] = useState(false);
    const [projStatusError, setProjStatusError] = useState(false);
    const [projDeptError ,setProjDeptError] = useState(false)

    useEffect(()=>{
        setUpdateProjects({
            projName: item?.projName,
            projectId: item?.projectId,
            clientName: item?.clientName,
            projectType: item?.projectType,
            categoryName: item?.categoryName,
            category_Id: item?.category_Id,
            departmentName: item?.departmentName,
            departmentId: item?.departmentId,
            status: item?.status,
        });
        setProjectType({ label: item?.projectType, value: item?.projectType })
        setCategoryName({label: item?.categoryName, value: item?.categoryName})
        setDepartmentName({label: item?.departmentName, value: item?.departmentName})
        setStatus({label: item?.status, value: item?.status})
    },[props])

    const { allCategoriesOptions } = useGetAllCategories()
    const { allDepartmentOptionsById } = useGetDepartments()
    const projectTypeOptions = [{ value: 'internal', label: 'Internal' }, { value: 'external', label: 'External' }];
    const projectStatusOptions = [{ value: 'inprogress', label: 'Inprogress' }, { value: 'completed', label: 'Completed' },
                                 { value: 'planed', label: 'Planed' }, { value: 'in support', label: 'In Support' }];

    let name, value;
    const handleUpdateProject = (e) =>{
        name = e.target.name;
        value = e.target.value;
        setUpdateProjects({ ...updateProjects, [name]: value });
    }

    const handlerValidate = () => {
        let FormValid = true;
    
        if (updateProjects.projName.length === 0) {
          FormValid = false;
          setProjNameError(true);
        }
        if (updateProjects.clientName.length === 0) {
          FormValid = false;
          setProjClientError(true);
        }
        if (projectType.length === 0) {
          FormValid = false;
          setProjTypeError(true);
        }
        if (categoryName.length === 0) {
          FormValid = false;
          setProjCategoryError(true);
        }
        if (status.length === 0) {
          FormValid = false;
          setProjStatusError(true);
        }
    
        if (departmentName.length === 0) {
          FormValid = false;
          setProjDeptError(true);
        }
        return FormValid;
      };
    
    const handleEditSubmit = async() =>{
        setProjNameError(false);
        setProjClientError(false);
        setProjTypeError(false)
        setProjCategoryError(false);
        setProjStatusError(false);
        setProjDeptError(false)

        if(handlerValidate()){
            const payload = {
                projName: updateProjects.projName,
                projectId: updateProjects.projectId,
                clientName: updateProjects.clientName,
                projectType: projectType.value,
                categoryName: categoryName.label,
                category_Id: updateProjects.category_Id,
                departmentName: departmentName.label,
                departmentId: updateProjects.departmentId,
                status: status.value,
            }
            await axios.post(process.env.REACT_APP_BASE_URL + "/project/updateProject", payload)
            .then((res) => {
                toastSuccess()
                setTimeout(()=>{
                  props.close()
                }, 1000)
            })
            .catch((err) => {
              console.log("Error from update api", err);
            })
        }
    }

  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={close} size="lg" centered backdrop='static' keyboard={false}>
    <Modal.Header
      closeButton
      style={{
        color: "black",
        width: "100%",
        height: "50px",
        backgroundColor: "#F9D65F",
      }}
    >
      <Modal.Title style={{ fontWeight: "bold", fontFamily: "Poppins" }}>
        Edit Project
      </Modal.Title>
    </Modal.Header>
    <Modal.Body size="lg">

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group controlId="formBasicId">
              <Form.Label className="mt-3 fw-bold">
                Project Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Project Name"
                  className="input-field project-inputgroup"
                  name="projName"
                  value={updateProjects.projName}
                  onChange={(e) => {
                    if (!e.target.value.match(/[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/) &&
                        e.target.value.trim()
                    ) {
                        handleUpdateProject(e);
                    } else if (e.target.value.length === 0) {
                        handleUpdateProject(e);
                    }
                    updateProjects.projName.length >= 0
                      ? setProjNameError(false)
                      : setProjNameError(true);
                  }}
                />
              </InputGroup>
              {projectNameError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group controlId="">
              <Form.Label className="mt-3 fw-bold">
                Client Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="Client Name"
                  name="clientName"
                  value={updateProjects.clientName}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[+@#$&%!~=^_:(){}\[\]*.|//\-/?<>,;`'""/\\]/
                      ) &&
                      e.target.value.trim()
                    ) {
                        handleUpdateProject(e)
                    } else if (e.target.value.length === 0) {
                        handleUpdateProject(e)
                    }
                    updateProjects.clientName.length >= 0 ? 
                    setProjClientError(false) : 
                    setProjClientError(true);
                  }}
                />
              </InputGroup>
              {projectClientError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
        <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Category
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='categoryName'
                value={categoryName}
                options={allCategoriesOptions}
                onChange={(option) => {
                  setCategoryName(option);

                  updateProjects.categoryName.length < 0
                    ? setProjCategoryError(true)
                    : setProjCategoryError(false);
                }}
              />
              {projCategoryError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Department Name
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='departmentName'
                value={departmentName}
                options={allDepartmentOptionsById}
                onChange={(option) => {
                  setDepartmentName(option);
                  departmentName.length < 0
                    ? setProjDeptError(true)
                    : setProjDeptError(false);
                }}
              />
              {projDeptError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Type
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='projectType'
                value={projectType}
                options={projectTypeOptions}
                onChange={(option) => {
                  setProjectType(option);
                  projectType.length < 0
                    ? setProjTypeError(true)
                    : setProjTypeError(false);
                }}
              />
              {projTypeError ? (
                <p className="error-padding" style={{ color: "red" }}>
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>

          <Col sm={12} md={6}>
            <Form.Group className="">
              <Form.Label className="mt-3 fw-bold">
                Project Status
                <span className="requiredfield">*</span>
              </Form.Label>
              <Select
                isMulti={false}
                isSearchable={true}
                name='status'
                value={status}
                options={projectStatusOptions}
                onChange={(option) => {
                  setStatus(option);
                  status.length < 0
                    ? setProjStatusError(true)
                    : setProjStatusError(false);
                }}
              />
              {projStatusError ? (
                <p className="errMsg">
                  <>
                    <Icon.ExclamationTriangle className="me-2" />
                  </>
                  Required Field
                </p>
              ) : null}
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="text-center mt-4">
        <Button className="primaryBtn " 
        onClick={() => { handleEditSubmit() }}
        >
          <Icon.PersonAdd className='me-2' /> Edit Project
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
